<app-spinner [isLoading]="isLoading$ | async">
  <div *ngIf="!(isLoading$ | async)">
      <div *ngIf="!(pageDoesNotExist$ | async)">
      <div *ngIf="{customer: customer$ | async,
            isSignedIn: isSignedIn$ | async,
            user: user$ | async,
            customerAnnouncementList: customerAnnouncementList$ | async,
            customerDescription: customerDescription$ | async,
            isCustomerDescriptionLong: isCustomerDescriptionLong$ | async,
            isWithFullDescription: isWithFullDescription$ | async,
            isJobseekerSignedIn: isJobseekerSignedIn$ | async,
            isCustomerSignedIn: isCustomerSignedIn$ | async,
            isCustomerLoaded: isCustomerLoaded$ | async,
            providerWebsiteUrlMap: providerWebsiteUrlMap$ | async} as data"
       class="container"
       [ngStyle]="((data.customer?.isWithBranding && data.customer.isCustomerDetailsBackgroundColorCustomizable) ? {'background': data.customer.customerDetailsBgColor} : {})">
    <div class="cover-absolute cover-absolute--visible-img" 
         [ngClass]="{'cover-absolute--visible': data.customer?.isWithCover}" 
         [ngStyle]="{'background-image': 'url(' + data.customer?.coverImageUrl + ')'}">
    </div>

    <div *ngIf="data.isCustomerLoaded" class="main-content" [ngClass]="{'main-content--without-cover': !data.customer?.isWithCover}">
      <div class="content" [ngClass]="{'content--cover-and-banner':data.customer?.isWithCover && IsBannerVisible,
                      'content--not-cover-and-banner': !data.customer?.isWithCover && !IsBannerVisible,
                      'content---banner':IsBannerVisible && !data.customer?.isWithCover,
                      'content--cover': data.customer?.isWithCover && !IsBannerVisible}">


        <div *ngIf="data.customer?.isWithCover" class="cover">
          <img class="cover__img" src="{{data.customer?.coverImageUrl}}">
        </div>

        <div class="logo" [ngClass]="{'logo--without-cover': !data.customer?.isWithCover}">
          <div class="logo-area">
            <img *ngIf="data.customer?.logoHref != null" class="logo__img" src="{{data.customer?.logoHref}}">
          </div>
        </div>

        <div *ngIf="data.customer?.hasActiveCvBox" class="cv-box">
          <h2 class="cv-box__title">{{'label.CvBox' | translate}}</h2>
          
          <div class="cv-box__basket" [ngStyle]="{backgroundImage:'url(assets/img/cv-box/box.svg)'}">
            <div class="cv-box__cube-front">
              <div class="cv-box__cube-front-content">
                <img class="cv-box__img" src="{{data.customer.logoThumbnailHref}}" alt="{{data.customer.name}}">
              </div>
            </div>
          </div>
          
          <div class="cv-box__send-cv">
            <div class="cv-box__text">{{'label.LeaveCvOffer' | translate}}</div>
            <button *ngIf="!data.customer?.isBeenApplied"
                    [ngClass]="{'cv-box__btn--active':!data.isSignedIn || data.isJobseekerSignedIn,
                                'cv-box__btn--inactive':data.isCustomerSignedIn}"
                    (click)="onCvSend(data.customer?.customerId, data.isSignedIn, data.isJobseekerSignedIn)">
              {{'label.LeaveCv' | translate}}
              <p class="cv-box__btn-tooltip-text">{{'label.CanNotLeaveCvWithCompany' | translate}}</p>
            </button>

            <button *ngIf="data.isJobseekerSignedIn && data.customer?.isBeenApplied" class="cv-box__btn--inactive">
              {{'label.CvAlreadyLeft' | translate}}
            </button>
          </div>
          <div class="cv-box__description" [innerHTML]="'text.CvBoxDescriptionAdvantages' | translate | safeHtml"></div>
        </div>

        <div class="company">
          <h2 class="company__title">{{data.customer?.name}}</h2>
          <a *ngIf="data.customer?.showWebsite" class="company__website--small-screen" [href]="'//' + data.customer?.website" target="_blank">{{data.customer?.website}}</a>

          <div [ngClass]="{'company__details--without-background' : !data.customer?.showIndustry && !data.customer?.showNumberOfEmployees && !data.customer?.showDescription}" class="company__details">
            <div *ngIf="data.customer?.showIndustry" class="company__sector">
              <img class="company__sector-icon" src="assets/img/common/sector-icon.svg" alt="">
              <div class="company__sector-text">{{'label.Sector' | translate}}</div>
              <div class="company__sector-value">{{data.customer?.industry.name}}</div>
            </div>
            <div *ngIf="data.customer?.showNumberOfEmployees" class="company__employees">
              <img class="company__employees-icon" src="assets/img/common/company-size-icon.svg" alt="">
              <div class="company__employees-text">{{'label.CompanySize' | translate}}</div>
              <div class="company__employees-value">{{data.customer?.numberOfEmploees}}</div>
            </div>
            <div *ngIf="data.customer?.showWebsite" class="company__website">
              <img class="company__website-icon" src="assets/img/common/website-icon.svg" alt="">
              <div class="company__website-text">{{'label.Website' | translate}}</div>
              <a class="company__website-value" [href]="'//' + data.customer?.website" target="_blank">{{data.customer?.website}}</a>
            </div>
            <p *ngIf="data.customer?.showDescription" class="company__description-text" [innerHTML]="data.customer.description | safeHtml"> </p>

            <div class="company__description-text--mobile">
              <p [innerHTML]="data.customerDescription | safeHtml"></p>
              <button *ngIf="data.isCustomerDescriptionLong" class="company__see-description" (click)="showText()">[ {{(data.isWithFullDescription ? 'label.SeeLess' : 'label.SeeMore') | translate}} ]</button>
            </div>
          </div>
        </div>

        <div class="social-media">
          <!-- <a class="social-media__fb" href="">
            <img src="assets/img/common/fb-icon.svg" alt="">
          </a>
          <a class="social-media__linkedin" href="">
            <img src="assets/img/common/in-icon.svg" alt="">
          </a>
          <a class="social-media__twiter" href="">
            <img src="assets/img/common/twitter-icon.svg" alt="">
          </a> -->
        </div>

        <div class="announcement">
          <h2 *ngIf="data.customerAnnouncementList?.length > 0" class="announcement__text">
            {{'label.CurrentAnnouncements' | translate}}
          </h2>
          <app-announcement-item [providerWebsiteUrlMap]="data.providerWebsiteUrlMap" *ngFor="let announcement of data.customerAnnouncementList" [announcement]="announcement" [pageName]="pageName.CustomerPublicPage" [date]="date"></app-announcement-item>
        </div>

        <div *ngIf="IsBannerVisible" class="banner">
          <!--TODO: banner is not done.. temporary img src-->
          <img class="banner_img" src="{{data.customer?.logoHref}}">
        </div>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf="(pageDoesNotExist$ | async) && !(isLoading$ | async)">
    <app-not-existing-page></app-not-existing-page>
  </div>
  </div>
</app-spinner>
