<div class="container">
    <div class="search">
        <app-search-and-filter></app-search-and-filter>
    </div>
    <app-spinner [isLoading]="isLoading$ | async" [isWithSearchPanel]="true">
        <div *ngIf="{
                topBanner: frontPageTopBanner$ | async,
                bottomBanner: frontPageBottomBanner$ | async,
                providerWebsiteUrlMap: providerWebsiteUrlMap$ | async } 
                as data" class="content">
            
            <!-- Top Banner -->
            <div style="margin-top: 1.25rem;" class="banner">
                <app-banner [banner]="data.topBanner" [pageId]="pageId.FrontPage"></app-banner>
            </div>
            
            <!-- Favourite Announcement List-->
            <div class="ann-list">
                <app-announcement-item 
                    *ngFor="let announcement of favoriteAnnouncements$ | async" 
                    [announcement]="announcement" [providerWebsiteUrlMap]="data.providerWebsiteUrlMap" [date]="date">
                </app-announcement-item>
            </div>

            <!-- Bottom Banner -->
            <div class="banner">
                <app-banner [banner]="data.bottomBanner" [pageId]="pageId.FrontPage"></app-banner>
            </div>
        </div>
    </app-spinner>
</div>
