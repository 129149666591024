<div class="cv-box-item" [ngStyle]="{backgroundImage:'url(assets/img/cv-box/box.svg)'}">
    <div class="cube-front-facet">
        <div class="cv-box-content">
            <div class="cv-box-content__company-logo" [ngClass]="{'company-logo--white-background':cvBox.islogoWithWiteBackground}">
                <img title="{{cvBox.customerName}}" class="cv-box-content__company-logo-img" [src]="cvBox.logoHref" alt="customer-logo"> 
            </div>
            <a [routerLink]="['/customer', cvBox.customerId, cvBox.urlFriendlyCustomerName]" class="cv-box-content__link">
                <button class="cv-box-content__btn" href="">{{'label.LeaveCv' | translate}}</button>
            </a>
        </div>
    </div>
</div>