import { Injectable } from '@angular/core';
import { LocalDataStorageService } from '@hrra/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { Observable, throwError } from 'rxjs';
import { AuthResult, UserData, CurrentUserInfo } from './auth-result';
import { ApiResponse, EmptyResponse, ApiError } from '@hrra/core';
import { Store } from '@ngrx/store';
import { AppState } from '../root-store/state';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserTypeEnum } from '../user/shared/models/enums/user-type-enum';
import { UrlConfig, UrlConfigContainer } from './url-config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private jwtHelperService: JwtHelperService;

  public getNewToken(): Observable<ApiResponse<AuthResult>> {
    if (this.storage.hasAccessToken()) {
      return this.http.post<AuthResult>(
        this.urlService.urlConfig.api.data.auth.refreshAccessToken,
        {},
        true
      );
    } else {
      return throwError(<ApiError>{ errorMessage: 'Uset Not signed in' });
    }
  }

  public storeAccessToken(accessToken: string) {
    this.storage.storeAccessToken(accessToken);
  }

  public destroyAccessToken() {
    this.storage.destroyAccessToken();
  }

  public login(
    userName: string,
    password: string,
    staySignedIn: boolean
  ): Observable<ApiResponse<AuthResult>> {
    let data = {
      UserName: userName,
      Password: password,
      StaySignedIn: staySignedIn,
    };

    return this.http.post<AuthResult>(
      this.urlService.urlConfig.api.data.auth.login,
      data
    );
  }

  public logout(): Observable<ApiResponse<EmptyResponse>> {
    return this.http.post<EmptyResponse>(
      this.urlService.urlConfig.api.data.auth.logout,
      {}
    );
  }

  public getCurrentUserInfo(): Observable<ApiResponse<CurrentUserInfo>> {
    return this.http.post<CurrentUserInfo>(
      this.urlService.urlConfig.api.data.auth.getUserInfo,
      {}
    );
  }

  public isSignedIn(): boolean {
    return (
      this.storage.hasAccessToken() &&
      !this.jwtHelperService.isTokenExpired(this.storage.getAccessToken())
    );
  }

  public getUserId() {
    return this.isSignedIn()
      ? this.jwtHelperService.decodeToken(this.storage.getAccessToken()).sub
      : this.storage.getAnonymousGuid();
  }

  public isJobseekerSignedIn() {
    return (
      this.isSignedIn() &&
      this.jwtHelperService.decodeToken(this.storage.getAccessToken())
        .userType == UserTypeEnum.Jobseeker
    );
  }

  constructor(
    private storage: LocalDataStorageService,
    private http: SmartHttpService,
    private urlService: UrlConfigService<UrlConfigContainer>,
    private store: Store<AppState>
  ) {
    this.jwtHelperService = new JwtHelperService();
  }
}
