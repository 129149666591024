<div class="container">
    <app-search-and-filter></app-search-and-filter>
</div>

<app-spinner [isLoading]="isLoading$ | async" [isWithSearchPanel]="true">
  <div *ngIf="{
       topBanner: frontPageTopBanner$ | async,
       midBanner: frontPageMidBanner$ | async,
       midBanner2: frontPageMidBanner2$ | async,
       bottomBanner: frontPageBottomBanner$ | async,
       vipAnnouncements: vipAnnouncements$ | async,
       mergedAnnouncements: mergedAnnouncements$ | async,
       isCvBoxSliderVisible: isCvBoxSliderVisible$ | async 
       } as data"
       class="content">

    <!-- Top Banner -->
    <div class="banner">
      <app-banner [banner]="data.topBanner" [pageId]="pageId.FrontPage"></app-banner>
    </div>

    <!-- Vip Cv Ann -->
    <div class="announcement section section--vip-cv-announcement">
      <app-announcement-item *ngFor="let announcement of vipCvAnnouncements$ | async; trackBy:announcementId" [announcement]="announcement" [pageName] = "pageName.FrontPage" [date]="date"></app-announcement-item>
    </div>

    <!-- Mid Banner -->
    <div class="banner">
      <app-banner [banner]="data.midBanner" [pageId]="pageId.FrontPage"></app-banner>
    </div>

    <!--Vip CV BOX SECTION-->
    <div class="section cv-box section--vip-cv-box">
      <app-cv-box-item *ngFor="let cvBox of vipCvBoxes$ | async; trackBy:cvBoxId" [cvBox]="cvBox" class="cv-box__item"></app-cv-box-item>
    </div>


    <!--Vip Ann-->
    <div class="search-results-container"
    infinite-scroll
    (scrolled)="onScrollDown($event)">
          <app-announcement-item *ngFor="let announcement of data.vipAnnouncements; trackBy:announcementId" [announcement]="announcement" [pageName] = "pageName.FrontPage" [date]="date"></app-announcement-item>
    </div>

    <!-- Mid Banner 2 -->
    <div class="banner">
      <app-banner [banner]="data.midBanner2" [pageId]="pageId.FrontPage"></app-banner>
    </div>

    <!--Premium CV BOX SECTION-->
    <div *ngIf="data.isCvBoxSliderVisible" class="section slider">
      <owl-carousel-o [options]="cvBoxSliderOptions"  class="carousel">
        <ng-container *ngFor="let cvBox of premiumCvBoxes$ | async">
          <ng-template carouselSlide [id]="cvBox.customerId">
            <app-cv-box-item ngxSlickItem [cvBox]="cvBox" class="cv-box-slider__item"></app-cv-box-item>
        </ng-template>
        </ng-container>  
      </owl-carousel-o>
    </div>

    <div class="search-results-container"
         infinite-scroll
         (scrolled)="onScrollDownMergedAnn($event)">
          <app-announcement-item *ngFor="let announcement of data.mergedAnnouncements; trackBy:announcementId" [announcement]="announcement" [pageName] = "pageName.FrontPage" [date]="date"></app-announcement-item>
    </div>

    <div *ngIf="isFrontPageLinkVisible$ | async" class="view-all-announcements">
      <a [routerLink]="['./search-posting']">{{'label.ViewAllAnnouncements' | translate}}</a>
    </div>

    <!--City links SECTION-->
    <div class="locality-links">
      <a *ngFor="let item of selectFrontPageCityLinks$ | async; trackBy:cityLinkId"  [routerLink]="['/search-posting/']"  [queryParams]="item.queryParams">
        <div class="locality-links__item">{{item.name}}</div>
      </a>
    </div>

    <!-- Bottom Banner -->
    <div class="banner">
      <app-banner [banner]="data.bottomBanner" [pageId]="pageId.FrontPage"></app-banner>
    </div>
  </div>
</app-spinner>
