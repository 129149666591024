import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from '../jobseeker-store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { JobseekerProfileEffects } from './jobseeker-profile';
import { JobseekerEffects } from './effects';
import { JobseekerRegistrationEffects } from './jobseeker-registration';
import { JobseekerSettingsEffects } from './jobseeker-settings';
import { JobseekerSubscriptionEffects } from './jobseeker-subscription'
import { JobseekerResetPasswordEffects } from './reset-password';
import { JobseekerAppliesEffects } from './jobseeker-applies';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('jobseeker',  reducers),
    EffectsModule.forFeature([JobseekerEffects, 
                              JobseekerProfileEffects.JobseekerProfileEffects,
                              JobseekerRegistrationEffects.JobseekerRegistrationEffects,
                              JobseekerSettingsEffects.JobseekerSettingsEffects,
                              JobseekerResetPasswordEffects.JobseekerResetPasswordEffects,
                              JobseekerSubscriptionEffects.JobseekerSubscriptionEffects,
                              JobseekerAppliesEffects.JobseekerAppliesEffects
                            ])
  ]
})
export class JobseekerStoreModule { }
