<app-spinner [isLoading]="isLoading$ | async">
    <div *ngIf="{ announcementDetails: announcementDetails$ | async,
        announcementNotFound: announcementNotFound$ | async,
        similarAnnouncements: similarAnnouncementList$ | async,
        favoriteAnnouncementsMap: favoriteAnnouncementsMap$ | async,
        timeZone: timeZone$ | async,
        topBanner: topBanner$ | async,
        rightBanner: rightBanner$ | async,
        rightBanner2: rightBanner2$ | async,
        rightBanner3: rightBanner3$ | async,
        rightBannerSquare: rightBannerSquare$ | async,
        middleBanner: middleBanner$ | async,
        bottomBanner: bottomBanner$ | async,
        isJobseekerSignedIn: isJobseekerSignedIn$ | async,
        isCustomerSignedIn: isCustomerSignedIn$ | async,
        isSignedIn: isSignedIn$ | async,
        user: user$ | async,
        isLoading: isLoading$ | async,
        isCvBoxSliderVisible: isCvBoxSliderVisible$ | async,
        desktopImage: desktopImage$ | async,
        mobileImage: mobileImage$ | async,
        ownAnnouncements: ownAnnouncements$ | async,
        currency: currency$ | async,
        currentLanguage: currentLanguage$ | async,
        previousAnnouncementId: previousAnnouncementId$ | async,
        nextAnnouncementId: nextAnnouncementId$ | async } 
        as data">
        <div class="container" 
             [ngStyle]="(!data.isLoading && data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null ? {background: data.announcementDetails.customerBranding?.announcementDetailsBgColor} : {})">
        
            <div *ngIf="data.announcementDetails != null && !data.isLoading && !data.announcementDetails?.expired && !data.announcementNotFound">
                <!--Banner Top-->
                <div class="banner banner--top">
                  <div class="banner">
                      <app-banner [banner]="data.topBanner" [pageId]="pageId.AnnouncementDetailsPage"></app-banner>
                  </div>  
                </div>
                
                <div class="container--left">
                    <div [ngClass]="{'logo-container--with-cover': targetId === target.Responsive && data.mobileImage != null || targetId === target.Desktop && data.desktopImage != null,
                                      'logo-container--without-imgs': (targetId === target.Responsive && data.mobileImage == null || targetId === target.Desktop && data.desktopImage == null) && !data.announcementDetails?.hasLogo}" 
                         class="logo-container">
                        <div class="chevrons--left">
                            <a [routerLink]="routerLink$ | async" [queryParams]="queryParams$ | async" class="back">
                                <svg-icon class="back__icon" src="/assets/icons/chevron-left-green.svg" 
                                          [svgClass]="'chevron'"
                                          [ngClass]="{'with-branding--icon': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                </svg-icon>
                                <span class="back__text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{'label.BackToSearchResults' | translate}}
                                </span>
                            </a>
                        </div>
    
                        <div class="imgs">
                            <div *ngIf="targetId === target.Responsive && data.mobileImage != null || targetId === target.Desktop && data.desktopImage != null"
                                 class="cover"
                                 [ngStyle]="{'background-image': targetId === target.Responsive ? 'url(' + data.mobileImage + ')' : 'url(' + data.desktopImage + ')'} ">
    
                            </div>
                            <div class="logo" 
                                 [ngClass]="{'logo--with-cover': targetId === target.Responsive && data.mobileImage != null || targetId === target.Desktop && data.desktopImage != null}"
                                 *ngIf="data.announcementDetails?.hasLogo">
                                <img class="logo__img" [src]="data.announcementDetails?.logoUrl" alt="">
                            </div>
                        </div>
    
                        <div class="chevrons--right" style="display: flex;">
                            <a  *ngIf="data.previousAnnouncementId != null; else elseAnnIsFirst" [routerLink]="['/announcement', data.previousAnnouncementId.announcementId, translit.getUrlFriendlyString(data.previousAnnouncementId.announcementTitle)]" [queryParams]="{nav:1}" class="previous">
                                <svg-icon class="previous__icon" 
                                          src="/assets/icons/chevron-left-green.svg" 
                                          [svgClass]="'chevron'"
                                          [ngClass]="{'with-branding--icon': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                </svg-icon>
                                <span class="previous__text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{'label.previous' | translate}}
                                </span>
                            </a>
                            <ng-template #elseAnnIsFirst>
                                <div class="previous">
                                    <svg-icon class="previous__icon" 
                                              src="/assets/icons/chevron-left-green.svg" 
                                              [svgClass]="'chevron'"
                                              [ngClass]="{'with-branding--icon': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    </svg-icon>
                                    <span class="previous__text inactive-link"
                                          [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                        {{'label.previous' | translate}}
                                    </span>
                                </div>
                            </ng-template>
              
                            <a *ngIf="data.nextAnnouncementId != null; else elseAnnIsLast"  [routerLink]="['/announcement', data.nextAnnouncementId.announcementId ,translit.getUrlFriendlyString(data.nextAnnouncementId.announcementTitle)]" [queryParams]="{nav:1}" class="next">
                                <span class="next__text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{'label.Next' | translate}}
                                </span>
                                <svg-icon class="next__icon" src="/assets/icons/chevron-right-green.svg" 
                                          [svgClass]="'chevron'"
                                          [ngClass]="{'with-branding--icon': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                </svg-icon>             
                            </a>
                            <ng-template #elseAnnIsLast>
                                <div class="next">
                                    <span class="next__text inactive-link"
                                          [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                        {{'label.Next' | translate}}
                                    </span>
                                    <svg-icon class="next__icon" src="/assets/icons/chevron-right-green.svg" 
                                              [svgClass]="'chevron'"
                                              [ngClass]="{'with-branding--icon': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    </svg-icon>             
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div [ngClass]="{'tile--with-logo': data.announcementDetails?.hasLogo}" class="tile">
                        <div class="additional-info" [ngClass]="{'additional-info--with-logo': data.announcementDetails?.hasLogo}">
                            <div class="additional-info__date">
                               <span [ngClass]="{'additional-info__date--istoday': data.announcementDetails.isPublishedToday}">{{data.announcementDetails.isPublishedToday ? ('label.Today' | translate) : ((publishDate$ | async) | date:'dd MMM':data.timeZone:data.currentLanguage)}} - </span>  
                               <span [ngClass]="{'additional-info__date--istoday': data.announcementDetails.isDeadLineToday}">{{data.announcementDetails.isDeadLineToday ? ('label.Today' | translate) : data.announcementDetails?.deadlineDate | date:'dd MMM':data.timeZone:data.currentLanguage}}</span>
                            </div>
                            <div class="additional-info__ann-type">
                                {{listingSectionNameMap[data.announcementDetails?.listingSection] | translate}}
                            </div>
                        </div>
    
                        <div class="tile-content">
                            <div class="main-info">
                                <div class="ann-title-container">
                                    <div class="ann-title-container__text">
                                        {{data.announcementDetails?.title}}
                                    </div>
                                    <svg-icon title="{{'label.WorkFromHome' | translate}}" *ngIf="data.announcementDetails?.isWorkFromHome" src="/assets/icons/home-green.svg" class="ann-title-container__icon" [svgClass]="'home-icon'"></svg-icon>
                                </div>
        
                                <div class="location">
                                    <hrra-announcement-location [locations]="data.announcementDetails?.extendedLocations"></hrra-announcement-location>
                                </div>
        
                                <div *ngIf="data.announcementDetails?.isAnonymous; else elseBlock" class="company-name">
                                    {{data.announcementDetails?.customerName}}
                                </div>
                                  
                                <ng-template #elseBlock>
                                    <div class="company-name">
                                        <a [routerLink]="['/customer', data.announcementDetails?.customerId, data.announcementDetails?.urlFriendlyCustomerName]" class="company-name__link">
                                            {{data.announcementDetails?.customerName}}
                                        </a>
                                    </div>
                                </ng-template>
                                
                                <div class="details">
                                    <ul class="list">
                                        <li class="list__item">{{data.announcementDetails?.employmentForm}}</li>
                                        <li *ngIf="data.announcementDetails?.salaryFrom != null && (!data.announcementDetails?.hideSalary || data.announcementDetails?.hideSalary == null)"  class="list__item">
                                            <span class="bullete"></span> <span>{{data.announcementDetails?.salaryFrom}}</span>
                                            <span *ngIf="data.announcementDetails?.salaryTo != null"> - {{data.announcementDetails?.salaryTo}}</span>
                                            <span *ngIf="data.announcementDetails?.salaryFrom != null || data.announcementDetails?.salaryTo != null">
                                                <svg-icon class="currency-icon" [src]="'assets/icons/'+ data.currency +'-icon.svg'"></svg-icon>
                                            </span>
                                            <span *ngIf="data.announcementDetails?.isWithBonus"> + {{'label.Bonus' | translate}} </span>
                                            <span *ngIf="data.announcementDetails?.bonus"> 
                                                {{data.announcementDetails?.bonus}} 
                                                <svg-icon class="currency-icon" [src]="'assets/icons/'+ data.currency +'-icon.svg'"></svg-icon>
                                            </span>
                                        </li>
                                        <li *ngIf="data.announcementDetails?.businessTravel" class="list__item">
                                            <span class="bullete"></span>
                                            <span>{{'label.BusinessTravel' | translate}}</span>
                                        </li>
                                    </ul>
                                </div>
        
                                <div class="requirements">
                                    <ul class="list">
                                        <li *ngIf="data.announcementDetails?.education != null" class="list__item">
                                            {{data.announcementDetails?.education}}
                                        </li>
                                        <li *ngIf="data.announcementDetails?.workExperienceType != null" class="list__item">
                                            <span *ngIf="data.announcementDetails?.workExperienceType != null" class="bullete"></span>
                                            <span *ngIf="data.announcementDetails?.workExperienceType != null" class="type">{{ announcementWorkExperienceEnumMap[data.announcementDetails.workExperienceType] | translate }}</span>
                                            <span *ngIf="data.announcementDetails?.workExperienceFrom != null && data.announcementDetails?.workExperienceTo != null">
                                                , {{ 'label.ExperienceYears' | translate }} {{ 'text.ExperienceYears' | translate: { count: data.announcementDetails?.workExperienceTo, fromyears: data.announcementDetails?.workExperienceFrom, toyears: data.announcementDetails?.workExperienceTo} }}
                                            </span>
                                            <span *ngIf="data.announcementDetails?.workExperienceFrom != null && data.announcementDetails?.workExperienceTo == null">
                                                , {{ 'label.ExperienceYears' | translate }} {{ 'text.ExperienceFromYears' | translate: { count: data.announcementDetails?.workExperienceFrom, fromyears: data.announcementDetails?.workExperienceFrom} }}
                                            </span>
                                            <span *ngIf="data.announcementDetails?.workExperienceTo != null && data.announcementDetails?.workExperienceFrom == null">
                                                , {{ 'label.ExperienceYears' | translate }} {{ 'text.ExperienceToYears' | translate: { count: data.announcementDetails?.workExperienceTo, toyears: data.announcementDetails?.workExperienceTo} }}
                                            </span>
                                        </li>
                                        <li *ngIf="data.announcementDetails?.drivingLicenses.length > 0" class="list__item driving-license">
                                          <span *ngIf="(data.announcementDetails?.experience != null || data.announcementDetails?.education != null)" class="bullete"></span> 
                                          <span class="driving-license__text">{{'label.DrivingLicence' | translate}}</span>
                                          <span *ngFor="let drivingLicense of data.announcementDetails?.drivingLicenses, let last = last, let first = first" class="driving-license__item">
                                              <span>{{drivingLicense}}{{last ? '' : ','}}</span>
                                          </span>
                                        </li>
                                        <li *ngIf="data.announcementDetails?.languages.length > 0" class="list__item language">
                                            <span *ngIf="(data.announcementDetails?.experience != null || data.announcementDetails?.education != null || data.announcementDetails?.drivingLicenses.length > 0)" class="bullete"></span>
                                            <span *ngFor="let language of data.announcementDetails?.languages, let last = last, let first = first" class="language__item">
                                                <span>{{language}}{{last ? '' : ','}}</span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
        
                                <div *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.EmailPhoneAddress" 
                                     class="application application-email-phone-address">        
                                    <div class="application__text">{{'label.ApplicationInformation' | translate}}</div>
                                    <div *ngIf="data.announcementDetails?.applicationDetails.email != null" class="application__email">
                                        <span>{{'label.Email' | translate}}: </span>
                                        <span>{{data.announcementDetails?.applicationDetails.email}}</span>
                                    </div>
                                    <div *ngIf="data.announcementDetails?.applicationDetails.phoneNumber != null" class="application__phone">
                                        <span>{{'label.Phone' | translate}}: </span>
                                        <span>{{data.announcementDetails?.applicationDetails.phoneNumber}}</span>
                                    </div>
                                    <div *ngIf="data.announcementDetails?.applicationDetails.applicationAddress != null" class="application__address">
                                        <span>{{'label.Address' | translate}}: </span>
                                        <span>{{data.announcementDetails?.applicationDetails.applicationAddress}}</span>
                                    </div>  
                                </div>
        
                            </div>
        
                            <div class="buttons">
                                <div class="send-cv">
                                    <button *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.ViaWebsite &&
                                           ((data.isJobseekerSignedIn && !data.announcementDetails?.isBeenApplied) || !data.isJobseekerSignedIn)"
                                        [ngClass]="{'customer-signed-in':data.isSignedIn && data.user?.userType == userType.customerAgent}"
                                        class="send-cv-btn"
                                        (click)="onCvSend(data.announcementDetails?.announcementId, data.isSignedIn, data.isJobseekerSignedIn)">
                                        {{'label.SendCv' | translate}}
                                    </button>
                                    <div *ngIf="data.isJobseekerSignedIn && data.announcementDetails?.isBeenApplied" class="cv-sent-btn">
                                        {{'label.CvSent' | translate}}
                                    </div>
                                    <a *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.RedirectToUrl"
                                        [ngClass]="{'customer-signed-in':data.isSignedIn && data.user?.userType == userType.customerAgent}" 
                                        class="send-cv-btn application-url-btn" 
                                        target="_blank" href="{{data.announcementDetails?.applicationDetails.applicationUrl}}">
                                        <span>{{'label.SendCv' | translate}}</span> 
                                        <span class="send-cv-btn__text-other-website"> ({{'label.OnAnotherWebsite'| translate}})</span>
                                    </a>
                                </div>
                                <button class="fav-btn" 
                                        [ngClass]="{'fav-btn--filled': data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId]}"
                                        (click)="onToggleFavorite(data.announcementDetails?.announcementId, data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId] === true)">
                                        <svg-icon [src]="data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId] ? '/assets/icons/fav-icon-filled-white.svg' : '/assets/icons/fav-icon2.svg'" class="fav-btn__icon-wrapper" [svgClass]="'fav-btn__icon'"></svg-icon>                                
                                        <span class="fav-btn__text">{{ (data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId] ? 'label.RemoveFromFavorites' : 'label.AddToFavorites' ) | translate}}</span>
                                </button>
                                <!-- <button class="share-btn">
                                    <svg-icon src="assets/img/common/share.svg" class="share-btn__icon-wrapper" [svgClass]="'share-btn__icon'"></svg-icon>                                
                                    <span class="share-btn__text">{{'label.ShareAnnouncement' | translate}}</span>
                                </button> -->
                            </div>
                            
                            <div *ngIf="data.announcementDetails?.hasAttachment" class="attachment">
                                <a [href]="data.announcementDetails.attachmentUrl" >
                                    <svg-icon class="attachment__icon" src="assets/icons/attachment.svg" [svgClass]="'attachment-icon'"></svg-icon>
                                    <span class="attachment__text">{{'label.Attachment' | translate}}</span>
                                  </a>
                            </div>
                                                                                    
                            <div class="send-cv-btn--mobile"
                                 *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.ViaWebsite || data.announcementDetails?.applicationMethod == applicationMethod.RedirectToUrl">
                                <button  *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.ViaWebsite &&
                                        ((data.isJobseekerSignedIn && !data.announcementDetails?.isBeenApplied) || !data.isJobseekerSignedIn)"
                                    [ngClass]="{'customer-signed-in':data.isSignedIn && data.user?.userType == userType.customerAgent}"
                                    class="send-cv-btn"
                                    (click)="onCvSend(data.announcementDetails?.announcementId, data.isSignedIn, data.isJobseekerSignedIn)">
                                    {{'label.SendCv' | translate}}
                                </button>
                                <div *ngIf="data.isJobseekerSignedIn && data.announcementDetails?.isBeenApplied" class="cv-sent-btn">
                                    {{'label.CvSent' | translate}}
                                </div>
                                <a *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.RedirectToUrl"
                                   [ngClass]="{'customer-signed-in':data.isSignedIn && data.user?.userType == userType.customerAgent}" 
                                    class="send-cv-btn application-url-btn" 
                                    target="_blank" href="{{data.announcementDetails?.applicationDetails.applicationUrl}}">
                                    <span>{{'label.SendCv' | translate}}</span> 
                                    <span class="send-cv-btn__text-other-website"> ({{'label.OnAnotherWebsite'| translate}})</span>
                                </a>
                            </div>
                            
    
                            <div class="description"
                                 [innerHTML] ="data.announcementDetails?.description | safeHtml">
                            </div>

                        </div>
                        <div class="buttons-bottom">
                            <div class="send-cv">
                                <button *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.ViaWebsite &&
                                       ((data.isJobseekerSignedIn && !data.announcementDetails?.isBeenApplied) || !data.isJobseekerSignedIn)"
                                    [ngClass]="{'customer-signed-in':data.isSignedIn && data.user?.userType == userType.customerAgent}"
                                    class="send-cv-btn"
                                    (click)="onCvSend(data.announcementDetails?.announcementId, data.isSignedIn, data.isJobseekerSignedIn)">
                                    {{'label.SendCv' | translate}}
                                </button>
                                <div *ngIf="data.isJobseekerSignedIn && data.announcementDetails?.isBeenApplied" class="cv-sent-btn">
                                    {{'label.CvSent' | translate}}
                                </div>
                                <a *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.RedirectToUrl"
                                    [ngClass]="{'customer-signed-in':data.isSignedIn && data.user?.userType == userType.customerAgent}" 
                                    class="send-cv-btn application-url-btn" 
                                    target="_blank" href="{{data.announcementDetails?.applicationDetails.applicationUrl}}">
                                    <span>{{'label.SendCv' | translate}}</span> 
                                    <span class="send-cv-btn__text-other-website"> ({{'label.OnAnotherWebsite'| translate}})</span>
                                </a>
                            </div>
                            <button class="fav-btn" 
                                    [ngClass]="{'fav-btn--filled': data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId]}"
                                    (click)="onToggleFavorite(data.announcementDetails?.announcementId, data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId] === true)">
                                    <svg-icon [src]="data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId] ? '/assets/icons/fav-icon-filled-white.svg' : '/assets/icons/fav-icon2.svg'" class="fav-btn__icon-wrapper" [svgClass]="'fav-btn__icon'"></svg-icon>                                
                                    <span class="fav-btn__text">{{ (data.favoriteAnnouncementsMap[data.announcementDetails?.announcementId] ? 'label.RemoveFromFavorites' : 'label.AddToFavorites' ) | translate}}</span>
                            </button>
                            <!-- <button class="share-btn">
                                <svg-icon src="assets/img/common/share.svg" class="share-btn__icon-wrapper" [svgClass]="'share-btn__icon'"></svg-icon>                                
                                <span class="share-btn__text">{{'label.ShareAnnouncement' | translate}}</span>
                            </button> -->
                        </div>
                    </div>
                    

                    <div class="categories-section">
                        <div *ngFor="let headCategory of data.announcementDetails?.categories | keyvalue" class="categories">
                            <div class="categories__head-categories"
                                 [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                 {{headCategory.key}}
                            </div>
                            <svg-icon class="categories__icon" src="/assets/icons/chevron-right-green.svg"
                                      [ngClass]="{'with-branding--icon': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                            </svg-icon>
                            <div class="categories__subcategories">
                                <div *ngFor="let category of headCategory.value, let last = last" 
                                     class="categories__subcategory"
                                     [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{category}}{{last ? '' : ','}}
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="stats">
                        <div class="stats__views stats__item">
                            <div>
                                <svg-icon src="/assets/icons/views.svg"></svg-icon>
                            </div>
                            <div>
                                <span class="stats__text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{'label.Views' | translate}} 
                                </span>
                                <span class="stats__count-text"
                                [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{data.announcementDetails?.totalViewsOnWebsite}}
                                </span>
                            </div>
                        </div>
                        <div class="stats__unique-views stats__item">
                            <div>
                                <svg-icon src="/assets/icons/unique-views.svg"></svg-icon>
                            </div>
                            <div>
                                <span class="stats__text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{'label.UniqueViews' | translate}} 
                                </span>
                                <span class="stats__count-text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{data.announcementDetails?.uniqueViewsOnWebsite}}
                                </span>
                            </div>
                        </div>
                        <div class="stats__sent-cvs stats__item" *ngIf="data.announcementDetails?.applicationMethod == applicationMethod.ViaWebsite">
                            <div>
                                <svg-icon src="/assets/icons/sent-cvs.svg"></svg-icon>
                            </div>
                            <div>
                                <span class="stats__text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{'label.SentCvs' | translate}}
                                </span>
                                <span class="stats__count-text"
                                      [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                                    {{data.announcementDetails?.totalApplicationsOnWebsite}}
                                </span>
                            </div>
                        </div>
                    </div>
            
                    <!--Banner middle-->
                    <div class="banner">
                      <app-banner [banner]="data.middleBanner" [pageId]="pageId.AnnouncementDetailsPage"></app-banner>
                    </div>
                 </div>
    
                 <div class="container--right">
                    <!--Banner right-->
                    <div class="banner banner--right3">
                        <app-banner [banner]="data.rightBanner3" [pageId]="pageId.AnnouncementDetailsPage"></app-banner>
                    </div>

                    <div class="banner">
                        <app-banner [banner]="data.rightBanner"></app-banner>
                    </div>
    
                    <div class="banner">
                        <app-banner [banner]="data.rightBanner2" [pageId]="pageId.AnnouncementDetailsPage"></app-banner>
                    </div>
                     
                    <!--own announcements-->
                    <div class="own-announcements" *ngIf="data.announcementDetails?.isWithBranding && data.announcementDetails?.customerBranding.announcementDetailsDisplayOtherAnnouncements && data.ownAnnouncements.length > 0">
                        <div class="own-announcements__text"
                             [ngClass]="{'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}">
                            {{'label.OwnAnnouncements' | translate}}
                        </div>
    
                        <div *ngFor="let ownAnnouncement of data.ownAnnouncements" class="own-announcements__item">
                            <app-similar-ann-item [similarAnnouncement]="ownAnnouncement"></app-similar-ann-item>
                        </div>
                    </div>
                    
                    <!--Cv Box-->
                    <div *ngIf="data.announcementDetails?.isWithBranding && data.announcementDetails.isWithCvBox" class="cv-box-container">
                        <h2 class="cv-box-container__title">{{'label.CvBox' | translate}}</h2>
                        <div class="cv-box-container__text">{{'label.LeaveCvOffer' | translate}}</div>
    
                        <app-cv-box-item [cvBox]="data.announcementDetails?.customerCvBox"></app-cv-box-item>
                    </div>
          
                    <div *ngIf="data.similarAnnouncements?.length > 0" class="similar-ann">
                        <div [ngClass]="{'similar-ann--without-top-banner': data.rightBanner == null && data.rightBanner2 == null,
                                         'with-branding--text': data.announcementDetails?.isWithBranding && data.announcementDetails.customerBranding?.announcementDetailsBgColor != null}" 
                             class="similar-ann__text">
                             {{'label.SimilarAnnouncements' | translate}}
                          </div>
                        <div *ngFor="let similarAnnouncement of data.similarAnnouncements" class="similar-ann__item">
                            <app-similar-ann-item [similarAnnouncement]="similarAnnouncement"></app-similar-ann-item>
                        </div>
                    </div>
                           
                    <!--Banner right -->
                    <div class="banner">
                        <app-banner [banner]="data.rightBannerSquare" [pageId]="pageId.AnnouncementDetailsPage"></app-banner>
                    </div>
                 </div>
    
                 <div class="cv-box-slider-container">
                    <div *ngIf="data.isCvBoxSliderVisible" class="cv-box-slider">   
                      <owl-carousel-o [options]="cvBoxSliderOptions" class="carousel">
                        <ng-container *ngFor="let cvBox of premiumCvBoxes$ | async">
                          <ng-template carouselSlide [id]="cvBox.customerId">
                            <app-cv-box-item ngxSlickItem [cvBox]="cvBox" class="cv-box-slider__item"></app-cv-box-item>
                        </ng-template>
                        </ng-container>  
                      </owl-carousel-o>
                    </div>
                  </div>
    
                  <div style="clear: both;"></div>
                  
                  <!--Banner bottom-->
                  <div class="banner banner">
                      <app-banner [banner]="data.bottomBanner" [pageId]="pageId.AnnouncementDetailsPage"></app-banner>
                  </div>
            </div>

            <div *ngIf="data.announcementNotFound || data.announcementDetails?.expired" class="unavailable-ann">
                <app-not-existing-page [errorType]="errorType.NotAvailable" [hideDefaultMessage]="true"></app-not-existing-page>
            
                <h4 class="unavailable-ann__title">
                    {{'message.AnnouncementExpiredOrDoesNotExist' | translate}}
                </h4>
                <br>
              
                <div *ngIf="data.similarAnnouncements?.length > 0" class="expired-ann">
                    <h4 class="expired-ann__text">
                        {{'label.YouMayInterest' | translate}}:
                    </h4>
              
                    <div class="expired-similar-ann-container">
                        <div *ngFor="let announcement of data.similarAnnouncements" class="expired-similar-ann">
                            <div class="expired-similar-ann__title">
                                <a class="expired-similar-ann__title-link" [routerLink]="['/announcement', announcement.announcementId]">{{announcement.title}}</a>
                            </div>
                            <div class="expired-similar-ann__company-name">{{announcement.customerName}}</div>
                            <div class="expired-similar-ann__dates">
                                {{(publishDate$ | async) | date:'dd MMM':data.timeZone:data.currentLanguage}} - {{announcement.deadlineDate | date:'dd MMM':data.timeZone:data.currentLanguage}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-spinner>
