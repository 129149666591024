import { Injectable } from '@angular/core';
import { SmartHttpService } from '@hrra/core';
import { UrlConfigService } from '@hrra/core';
import { Field } from '../models/enums/jobseeker-field-enum';
import { LocalDataStorageService } from '@hrra/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@hrra/core';
import { UrlConfig, UrlConfigContainer } from 'src/app/core/url-config';
import {
  BasicOptionItem,
  EducationalInstitution,
  EducationalProgram,
  FileDownloadInfoViewModel,
  Jobseeker,
  JobseekerExtendedLocationPath,
} from '@hrra/common-models';
import { JobseekerFile } from 'libs/common-models/src/lib/models/jobseeker/jobseeker-file-model';
import { ResetPasswordModel } from '@hrra/common-models';

@Injectable({
  providedIn: 'root',
})
export class JobseekerService {
  public cvExport() {
    return this.smartHttp.get<FileDownloadInfoViewModel>(
      this.urlService.urlConfig.api.data.jobseekers.cvExport,
      {}
    );
  }

  public getJobseeker() {
    return this.smartHttp.get<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.get,
      {}
    );
  }

  public getJobseekerLookup() {
    return this.smartHttp.get<JobseekerLookupData>(
      this.urlService.urlConfig.api.data.lookup.jobseekerLookup,
      {}
    );
  }

  public downloadDocumentAttachment(jobseekerDocumentId: number) {
    return this.smartHttp.get<FileDownloadInfoViewModel>(
      this.urlService.format(
        this.urlService.urlConfig.api.data.jobseekers.downloadDocument,
        { jobseekerDocumentId: jobseekerDocumentId }
      ),
      {}
    );
  }

  public downloadLanguageAttachment(jobseekerLanguageId: number) {
    return this.smartHttp.get<FileDownloadInfoViewModel>(
      this.urlService.format(
        this.urlService.urlConfig.api.data.jobseekers
          .downloadLanguageAttachment,
        { jobseekerLanguageId: jobseekerLanguageId }
      ),
      {}
    );
  }

  public downloadEducationAttachment(jobseekerEducationId: number) {
    return this.smartHttp.get<FileDownloadInfoViewModel>(
      this.urlService.format(
        this.urlService.urlConfig.api.data.jobseekers
          .downloadEducationAttachment,
        { jobseekerEducationId: jobseekerEducationId }
      ),
      {}
    );
  }

  public downloadExperienceAttachment(jobseekerWorkExperienceId: number) {
    return this.smartHttp.get<FileDownloadInfoViewModel>(
      this.urlService.format(
        this.urlService.urlConfig.api.data.jobseekers
          .downloadExperienceAttachment,
        { jobseekerWorkExperienceId: jobseekerWorkExperienceId }
      ),
      {}
    );
  }

  public deleteJobseekerDocument(documentId: number) {
    let data = {
      Field: Field.Document,
      JobseekerSubmitData: {
        Document: {
          JobseekerDocumentId: documentId,
          IsDeleted: true,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.get,
      data
    );
  }

  public deleteJobseekerLanguage(languageId: number) {
    let data = {
      Field: Field.Language,
      JobseekerSubmitData: {
        Language: {
          JobseekerLanguageId: languageId,
          IsDeleted: true,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.get,
      data
    );
  }

  public deleteJobseekerWorkExperience(experienceId: number) {
    let data = {
      Field: Field.WorkExperience,
      JobseekerSubmitData: {
        WorkExperience: {
          JobseekerWorkExperienceId: experienceId,
          IsDeleted: true,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.get,
      data
    );
  }

  public deleteJobseekerEducationn(educationId: number) {
    let data = {
      Field: Field.Education,
      JobseekerSubmitData: {
        Education: {
          JobseekerEducationId: educationId,
          IsDeleted: true,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.get,
      data
    );
  }

  //photo
  public deleteJobseekerPhoto() {
    let data = {
      Field: Field.Photo,
      JobseekerSubmitData: {
        Photo: {
          IsDeleted: true,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public editJobseekerPhoto(imageBase64: string) {
    let data = {
      Field: Field.Photo,
      JobseekerSubmitData: {
        Photo: {
          ImageBase64: imageBase64,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public editJobseekerPersonalInfo(personalInfo: JobseekerPersonalInfoModel) {
    let month =
      personalInfo.birthMonth < 10
        ? '0' + personalInfo.birthMonth
        : personalInfo.birthMonth;
    let day =
      personalInfo.birthDay < 10
        ? '0' + personalInfo.birthDay
        : personalInfo.birthDay;

    let data = {
      Field: Field.PersonalInfo,
      JobseekerSubmitData: {
        Name: personalInfo.name,
        Surname: personalInfo.surname,
        DateOfBirth: personalInfo.birthYear + '-' + month + '-' + day,
        City: personalInfo.city,
        Gender: personalInfo.gender,
        Address: personalInfo.address,
        LegalAddress: personalInfo.legalAddress,
        SameAsCurrentAddress: personalInfo.sameAsCurrentAddress,
        Email: personalInfo.email,
        Phone: personalInfo.phone,
        Phone2: personalInfo.phone2,
        DrivingLicenses: personalInfo.drivingLicenses,
        IsVehicleOwner: personalInfo.ownVehicle,
        ExtendedLocationId: personalInfo.extendedLocationId,
        JobseekerVehicles: personalInfo.jobseekerVehicles,
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public validateEmail(
    email: string
  ): Observable<
    ApiResponse<ServerValidationResult<JobseekerRegistrationValidationMessage>>
  > {
    return this.smartHttp.get(
      this.urlService.urlConfig.api.data.jobseekers.validateEmail,
      { email: email }
    );
  }

  public register(registrationRequest: JobseekerRegistrationRequestViewModel) {
    return this.smartHttp.post<JobseekerRegsitrationResponseViewModel>(
      this.urlService.urlConfig.api.data.jobseekers.register,
      registrationRequest
    );
  }

  public resendVerificationEmail(emailVerificationResendToken: string) {
    let data = { EmailVerificationResendToken: emailVerificationResendToken };

    return this.smartHttp.post(
      this.urlService.urlConfig.api.data.jobseekers.resendActivationEmail,
      data
    );
  }

  public verifyEmail(verificationCode: string) {
    let data = { VerificationCode: verificationCode };

    return this.smartHttp.post(
      this.urlService.urlConfig.api.data.jobseekers.verifyEmail,
      data
    );
  }

  public editJobseekerEducation(education: JobseekerEducationModel) {
    let data: any;

    let jobseekerEducation: any = {
      EducationLevelId: education.educationLevelId,
      EducationalInstitutionId: education.educationalInstitutionId,
      EducationalProgramId: education.educationalProgramId,
      EducationalProgramName: education.educationalProgramName,
      Institution: education.institution,
      Specialization: education.specialization,
      DateFromYear: Number(education.dateFromYear),
      DateFromMonth: education.dateFromMonth,
      DateToYear: Number(education.dateToYear),
      DateToMonth: education.dateToMonth,
      Description: education.description,
    };

    if (education.jobseekerEducationId != null) {
      jobseekerEducation.jobseekerEducationId = education.jobseekerEducationId;
    }

    if (education.fileName != null) {
      jobseekerEducation.Attachment = {
        Filename: education.fileName,
      };
    }

    data = {
      Field: Field.Education,
      JobseekerSubmitData: {
        Education: jobseekerEducation,
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public uploadTempFile(data: FormData) {
    let headers = new HttpHeaders();

    if (this.storage.hasAccessToken()) {
      headers = headers.append(
        'Authorization',
        `Bearer ${this.storage.getAccessToken()}`
      );
    }

    return this.http.post<any>(
      this.urlService.urlConfig.api.data.jobseekers.uploadTempFile,
      data,
      { headers: headers }
    );
  }

  public editJobseekerLanguage(language: JobseekerLanguageModel) {
    let data: any;

    let jobseekerLanguage: any = {
      WorldLanguageId: language.worldLanguageId,
      LanguageProficiencyLevelId: language.languageProficiencyLevelId,
    };

    if (language.jobseekerLanguageId != null) {
      jobseekerLanguage.JobseekerLanguageId = language.jobseekerLanguageId;
    }

    if (language.fileName != null) {
      jobseekerLanguage.Attachment = {
        Filename: language.fileName,
      };
    }

    data = {
      Field: Field.Language,
      JobseekerSubmitData: {
        Language: jobseekerLanguage,
      },
    };
    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public uploadJobseekerDocument(document: JobseekerDocumentViewModel) {
    let data = {
      Field: Field.Document,
      JobseekerSubmitData: {
        Document: {
          DocumentTypeId: document.documentTypeId,
          Description: document.comment,
          FileName: document.fileName,
        },
      },
    };

    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public editJobseekerWorkExperience(
    workExperience: JobseekerWorkExperienceModel
  ) {
    let data: any;

    let jobseekerWorkExperience: any = {
      DateFromMonth: workExperience.dateFromMonth,
      DateFromYear: +workExperience.dateFromYear,
      DateToMonth: workExperience.dateToMonth,
      DateToYear: !workExperience.isCurrent ? +workExperience.dateToYear : null,
      IsCurrent: workExperience.isCurrent,
      Employer: workExperience.employer,
      PositionTitle: workExperience.positionTitle,
      Description: workExperience.description,
      OccupationCategories: workExperience.occupationCategories,
    };

    if (workExperience.jobseekerWorkExperienceId != null) {
      jobseekerWorkExperience.JobseekerWorkExperienceId =
        workExperience.jobseekerWorkExperienceId;
    }
    if (workExperience.filename != null) {
      jobseekerWorkExperience.Attachment = {
        Filename: workExperience.filename,
      };
    }

    data = {
      Field: Field.WorkExperience,
      JobseekerSubmitData: {
        WorkExperience: jobseekerWorkExperience,
      },
    };
    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public setIsWorkExperienceFilled(isWorkExperienceFilled) {
    let data: any;

    data = {
      Field: Field.IsWorkExperienceFilled,
      JobseekerSubmitData: {
        isWorkExperienceFilled: isWorkExperienceFilled,
      },
    };
    return this.smartHttp.post<Jobseeker>(
      this.urlService.urlConfig.api.data.jobseekers.edit,
      data
    );
  }

  public updateSubscription(
    subscription: JobseekerSubscriptionUpdateViewModel
  ) {
    return this.smartHttp.post<JobseekerSubscriptionViewModel>(
      this.urlService.urlConfig.api.data.jobseekers.subscription.edit,
      subscription
    );
  }

  public getSubscription() {
    return this.smartHttp.get<JobseekerSubscriptionViewModel>(
      this.urlService.urlConfig.api.data.jobseekers.subscription.get,
      {}
    );
  }

  public resetPassword(request: ResetPasswordModel) {
    return this.smartHttp.post(
      this.urlService.urlConfig.api.data.jobseekers.resetPassword,
      request
    );
  }

  constructor(
    private smartHttp: SmartHttpService,
    private http: HttpClient,
    private urlService: UrlConfigService<UrlConfigContainer>,
    private storage: LocalDataStorageService
  ) {}
}

export interface JobseekerLookupData {
  jobseekerDrivingLicenses: BasicOptionItem[];
  genderList: BasicOptionItem[];
  drivingLicenceList: BasicOptionItem[];
  documentTypeList: BasicOptionItem[];
  worldLanguages: BasicOptionItem[];
  languageProficiencyLevels: BasicOptionItem[];
  educationLevels: BasicOptionItem[];
  educationalInstitutionsList: EducationalInstitution[];
  monthListFull: BasicOptionItem[];
  monthListShort: BasicOptionItem[];
  yearList: BasicOptionItem[];
  yearListBirth: BasicOptionItem[];
  jobseekerPrivacyList: BasicOptionItem[];
  vehicleTypes: BasicOptionItem[];
  educationProgramsList: EducationalProgram[];
}

export interface JobseekerPersonalInfoModel {
  name: string;
  surname: string;
  email: string;
  phone: string;
  phone2: string;
  address: string;
  legalAddress: string;
  sameAsCurrentAddress: boolean;
  gender: number;
  city: number;
  drivingLicenses: number[];
  ownVehicle: boolean;
  birthMonth: number;
  birthYear: number;
  birthDay: number;
  extendedLocationPath: JobseekerExtendedLocationPath;
  extendedLocationId: string;
  jobseekerVehicles: number[];
  jobseekerExtendedLocations: string[];
}

export interface ServerValidationResult<T> {
  isSuccess: boolean;
  items: ServerValidationResultItem<T>;
}

export interface ServerValidationResultItem<T> {
  messageType: T;
  statusLevel: StatusLevel;
}

export enum StatusLevel {
  None,
  Info,
  Warning,
  Error,
}

export enum JobseekerRegistrationValidationMessage {
  EmailRegisteredButBelongsToCustomer,
  EmailRegisteredButJobseekerPending,
  EmailRegisteredToAccessYourAccountPleaseLogin,
  EmailRegisteredToSubscriber,
  SubscriberEmailRegisteredButBelongsToCustomer,

  CannotConnectToServer,
}

export interface JobseekerRegistrationRequestViewModel {
  Email: string;
  Password: string;
  Name: string;
  Surname: string;
  PhoneNumber: string;
  ReCaptchaToken: string;
  IsAgreementChecked: boolean;
}

export interface JobseekerRegsitrationResponseViewModel {
  emailVerificationResendToken: string;
}

export interface JobseekerEducationModel {
  jobseekerEducationId: number;
  educationLevelId: number;
  educationalInstitutionId?: number;
  educationalProgramId?: number;
  educationalProgramName: string;
  institution: string;
  specialization: string;
  dateFromYear: number;
  dateFromMonth: number;
  dateToYear: number;
  dateToMonth: number;
  description: string;
  fileName: string;
  attachment: JobseekerFile;
}

export interface FileUploadResultViewModel {
  filename: string;
}

export interface JobseekerLanguageModel {
  jobseekerLanguageId: number;
  worldLanguageId: number;
  languageProficiencyLevelId: number;
  fileName: string;
  name: string;
}

export interface JobseekerDocumentViewModel {
  documentTypeId: number;
  comment: string;
  fileName: string;
}

export interface JobseekerWorkExperienceModel {
  jobseekerWorkExperienceId: number;
  dateFromMonth: number;
  dateFromYear: number;
  dateToMonth: number;
  dateToYear: number;
  isCurrent: boolean;
  employer: string;
  positionTitle: string;
  description: string;
  occupationCategories: any;
  filename: string;
}

export interface JobseekerSubscriptionViewModel {
  dailyDigestOn: boolean;
  subscibedCategoryIds: number[];
  selectedLocalityIds: number[];
}

export interface JobseekerSubscriptionUpdateViewModel {
  DailyDigestOn: boolean;
  OccupationCategoryIds: number[];
  LocalityIds: number[];
}
