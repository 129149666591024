import { Component, OnInit } from '@angular/core';
import { theme } from 'src/environments/theme';
import { Observable, combineLatest, filter, take } from 'rxjs';
import { CvBox } from '../shared/models/cv-box-model';
import { getCvBoxPageBannerList, loadCvBoxes, SearchCvBox } from 'src/app/root-store/cv-box-store/actions/cv-box-page-actions';
import { select, Store } from '@ngrx/store';
import * as fromCvBoxSelectors from 'src/app/root-store/cv-box-store/selectors'
import { UrlConfigService } from '@hrra/core';
import { AppState } from 'src/app/root-store/state';
import { ProviderWebsite } from 'src/app/shared/models/provider-website';
import * as selectors from 'src/app/root-store/selectors';
import { selectCvBoxPageMetaData, selectCvBoxPremiumBanner, selectCvBoxRegularBanner, selectCvBoxVipBanner, selectIsCvBoxPageLoading } from 'src/app/root-store/cv-box-store/selectors';
import { MetaService } from 'src/app/shared/services/meta.service';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { BannerTargetEnum } from 'src/app/banner/shared/models/enums/banner-target-enum';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { Breakpoints } from 'src/app/shared/models/breakpoints-model';
import { SsrService } from 'src/app/core/ssr.service';
import { selectCurrentWebsite } from 'src/app/root-store/selectors';
import { sendBannerViewToAnalytic } from 'src/app/root-store/actions';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

@Component({
  selector: 'app-cv-box-page',
  templateUrl: `./${theme.name}/cv-box-page.component.html`,
  styleUrls: [`./${theme.name}/cv-box-page.component.scss`]
})
export class CvBoxPageComponent implements OnInit {
  searchTerm: string;
  targetId: number = BannerTargetEnum.Desktop;
  pageId:any;

  vipCvBoxes$: Observable<CvBox[]>;
  premiumCvBoxes$: Observable<CvBox[]>;
  standardCvBoxes$: Observable<CvBox[]>;
  website$: Observable<ProviderWebsite>;
  isLoading$: Observable<boolean>;

  vipBanner$: Observable<Banner>;
  premiumBanner$: Observable<Banner>;
  regularBanner$: Observable<Banner>;
  
  constructor(private store: Store<AppState>,
              private metaService: MetaService,
              private ssr: SsrService) { 
                this.targetId = window.innerWidth > Breakpoints.medium ? BannerTargetEnum.Desktop : BannerTargetEnum.Responsive;
                this.pageId = BannerHeaderPositionId
              }

  ngOnInit(): void {

    let selectCvBoxPageBanners$ = this.store.select(fromCvBoxSelectors.selectCvBoxPageBannerIds);
    let providerWebsiteId$ = this.store.select(selectCurrentWebsite);

    combineLatest([selectCvBoxPageBanners$, providerWebsiteId$])
    .pipe(
      filter(([banners, provderWebsite]) =>{
        return banners.bannerIdList.length > 0 && provderWebsite !== null && provderWebsite !== undefined;
      }),
      take(1)
    )
    .subscribe(
      ([b, p]) => {
        this.store.dispatch(sendBannerViewToAnalytic({payload:{bannerIdList: b, providerWebsiteId:p.providerWebsiteId, pageId: BannerHeaderPositionId.FrontPage}}))
      }
    )


    this.store.dispatch(loadCvBoxes());
    if(this.ssr.isPlatformBrowser()){
      this.store.dispatch(getCvBoxPageBannerList({payload: {targetId: this.targetId, bannerHeadId: BannerPositionEnum.Cvbox_page}}));
    }

    this.vipCvBoxes$ = this.store.select(fromCvBoxSelectors.selectVipCvBoxes);
    this.premiumCvBoxes$ = this.store.select(fromCvBoxSelectors.selectPremiumCvBoxes);
    this.standardCvBoxes$ = this.store.select(fromCvBoxSelectors.selectStandardCvBoxes);
    
    this.website$ = this.store.select(selectors.selectCurrentWebsite);

    this.isLoading$ = this.store.pipe(select(selectIsCvBoxPageLoading));

    this.vipBanner$ = this.store.pipe(select(selectCvBoxVipBanner));
    this.premiumBanner$ = this.store.pipe(select(selectCvBoxPremiumBanner));
    this.regularBanner$ = this.store.pipe(select(selectCvBoxRegularBanner));

    this.store.pipe(select(selectCvBoxPageMetaData)).subscribe(
      (data) => {
        this.metaService.updateMetaData(data.website, data.metaData);
      }
    )
  }

  onSearchClick(searchTerm: string){
    this.store.dispatch(SearchCvBox({payload: searchTerm}));

  }
}
