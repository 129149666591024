//export {cvBoxReducer} from './cv-box-store/reducer';

import { WebsiteInfoState, CurrentInfoState, FavoritesState } from './state';
import * as Actions from './actions';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';
import { createImmerReducer, immerOn } from 'ngrx-immer/store';
import { LookupState, AppState, MessageState } from './state';
import { state } from '@angular/animations';
import { PageNameEnum } from '../shared/models/enums/page-names-enum';
import { OptionItem } from '@hrra/common-models';

let initialState: WebsiteInfoState = {
  partnerSitesForFooter:null,
  configs: null, //TODO
  loading: false,
};

const reducerLookup = createReducer(
  initialState,
  on(Actions.loadPartnerSitesLookups, (state) => ({
    ...state,
    loading: true,
  })),
  on(Actions.loadPartnerSitesSuccess, (state, { payload }) => ({
    ...state,
    partnerSitesForFooter: payload,
    loading: false,
  })),
  on(Actions.loadPartnerSitesLookupsFailed, (state, { payload }) => ({
    ...state,
    loading: false,
  })),

  on(Actions.loadConfigs, (state) => ({
    ...state,
    loading: false,
  })),
  on(Actions.loadConfigsSuccess, (state, { payload }) => ({
    ...state,
    configs: payload,
    loading: false,
  })),
  on(Actions.loadConfigsFail, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
);

export function lookupReducer(
  state: WebsiteInfoState | undefined,
  action: Action
) {
  return reducerLookup(state, action);
}

//TODO: language refactoring
let currentInfoInitialState: CurrentInfoState = {
  currentLanguage: null,
  defaultLanguage: null,
  alternativeLanguage: null,

  currenWbeiste: null,
  lastListingPage: null,
  isSignedIn: false,
  user: null,
  loading: false,
  isSignInError: false,
  signInFailureDetails: null,
  currentDate: null,
};

const reducerCurrentInfo = createImmerReducer(
  currentInfoInitialState,
  on(Actions.loadCurrentInfo, (state) => ({
    ...state,
    loading: true,
  })),
  //TODO:
  on(Actions.loadCurrentInfoSuccess, (state, { payload }) => ({
    ...state,
    // currentLanguage: payload.currentLanguage,
    // currenWbeiste: payload.currenWbeiste,
    // isSignedIn: payload.isSignedIn,
    // user: payload.user,
    loading: false,
  })),
  on(Actions.loadCurrentInfoFail, (state, { payload }) => ({
    ...state,
    loading: false,
  })),
  on(Actions.loadLanguageSuccess, (state, { payload }) => ({
    ...state,
    currentLanguage: payload.languageId,
  })),
  on(Actions.loadProviderWebsiteInfo, (state) => ({
    ...state,
    loading: true
  })),
  on(Actions.loadProviderWebsiteInfoSuccess, (state, { payload }) => ({
    ...state,
    currenWbeiste: payload.providerWebsite,
    loading: false,
  })),
  on(Actions.loadProviderWebsiteInfoFail, (state, { payload }) => ({
    ...state,
    loading: false,
  })),
  on(Actions.loadLastListingPage, (state, { payload }) => {
    if(payload != PageNameEnum.AnnouncementDetails){
      state.lastListingPage = payload;
    }
    return state;
  }),


  on(Actions.login, (state, { payload }) => ({
    ...state,
    loading: true
  })),

  on(Actions.loginSuccess, (state, { payload }) => {
    state.user = {
      name: payload.userData.displayName,
      surname: payload.userData.displayName,
      userType: payload.userData.userType,
      isPasswordChangeRequired: payload.userData.isPasswordChangeRequired,
      userId: payload.userData.userId
    };

    state.isSignedIn = true;
    state.loading = false;

    return state;
  }),

  on(Actions.loginFailure, (state, { payload }) => {
    state.isSignedIn = false;
    state.isSignInError = true;
    state.loading = false;
    state.signInFailureDetails = payload;

    return state;
  }),

  on(Actions.loginValidation, (state, { payload }) => {
    state.isSignInError = payload.credentialsInvalid;

    return state;
  }),
  on(Actions.clearLoginPageInfo, (state) => {
    state.isSignInError = false;
    state.signInFailureDetails = null;
    return state;
  }),

  on(Actions.getNewAccessTokenSuccess, (state, { payload }) => {
    state.user = {
      name: payload.userData.displayName,
      surname: payload.userData.displayName,
      userType: payload.userData.userType,
      isPasswordChangeRequired: payload.userData.isPasswordChangeRequired,
      userId : payload.userData.userId
    };

    state.isSignedIn = true;

    return state;
  }),

  on(Actions.logout, (state) => ({
    ...state,
    loading: true
  })),

  on(Actions.logoutSuccess, (state, action) => {
    state.user = null;
    state.isSignedIn = false;
    state.loading = false;

    return state;
  }),

  on(Actions.getNewAccessTokenFailure, (state, action) => {
    state.user = null;
    state.isSignedIn = false;
    state.loading = false;

    return state;
  }),
  on(Actions.loadConfigsSuccess, (state, { payload }) => {
    state.alternativeLanguage = payload.systemConfigs.common.alternativeLanguage;
    state.defaultLanguage = payload.systemConfigs.common.primaryLanguage;
    return state;
  }),
  on(Actions.setCurrentDate, (state, { payload }) => {
    state.currentDate = payload.date;
    return state;
  })
);

export function currentInfoReducer(
  state: CurrentInfoState | undefined,
  action: Action
) {
  return reducerCurrentInfo(state, action);
}

const initialLookupState = <LookupState>{
  announcementTypes: [],
  announcementTypeOptions: [],
  localities: [],
  occupationCategories: [],
  publishDateFilterOptions: [],
  salaryFilterOptions: [],
  workExperienceFilterOptions: [],
  commonLookupsLoaded: false
};
const initialMessageState = <MessageState>{};

export function toLookupList<T>(list: OptionItem<T>[]) {
  return list.map((c, i) => {
    let t = Object.assign({}, c);
    t.key = i;
    if (t.isEmpty) {
      t.value = undefined;
    }

    return t;
  });
}

const _lookupReducer = createImmerReducer(
  initialLookupState,
  on(Actions.commonLookupsLoaded, (state, { payload }) => {
    state.localities = payload.localities;
    state.announcementTypes = payload.announcementTypes;
    state.announcementTypeOptions = payload.announcementTypeOptions;
    state.workExperienceFilterOptions = toLookupList(
      payload.workExperienceFilterOptions
    );
    state.salaryFilterOptions = toLookupList(payload.salaryFilterOptions);
    state.publishDateFilterOptions = toLookupList(
      payload.publishDateFilterOptions
    );

    let occupationCategories = payload.occupationCategories.map((c) =>
      Object.assign({}, c)
    );

    occupationCategories.forEach((c) => {
      c.childCategoryIds = occupationCategories
        .filter(
          (cc) =>
            cc.headCategoryId == c.categoryId && cc.categoryId != c.categoryId
        )
        .map((cc) => cc.categoryId);
    });

    state.occupationCategories = occupationCategories;
    
    state.commonLookupsLoaded = true;

    return state;
  })
);

export function lookupReducerMy(
  state: LookupState | undefined,
  action: Action
) {
  return _lookupReducer(state, action);
}

// export const reducers: ActionReducerMap<AppState> = {
//     lookup: lookupReducer,
//     lookupMy: lookupReducerMy,
//     currentInfo: currentInfoReducer,
//     customer: customerReducer,
//     cvBox: cvBoxReducer,

// };

const initialFavoritesState: FavoritesState = {
  favoriteAnnouncementIds: []
};

const _favoritesReducer = createImmerReducer(initialFavoritesState,
  on(Actions.loadFavortiesSuccess, (state, { payload }) => {
    state.favoriteAnnouncementIds = payload.announcementIds;
    return state;
  }),
  on(Actions.addToFavoritesSuccess, (state, { payload }) => {
    state.favoriteAnnouncementIds = payload.updatedFavoriteAnnouncementIds;
    return state;
  }),
  on(Actions.removeFromFavoritesSuccess, (state, { payload }) => {
    state.favoriteAnnouncementIds = payload.updatedFavoriteAnnouncementIds;
    return state;
  }),
);

export function favoritesReducer(
  state: FavoritesState | undefined,
  action: Action
)
{
  return _favoritesReducer(state, action);
}
