export enum HRRAExceptionCode{
    Unspecified = 1,
    MalformedUserAuthenticationToken = 2,
    UserDoesNotExist = 3,
    SessionExpired = 4,
    SessionDoesNotExist = 5,
    AttemptOfUnauthorizedAccess = 6,
    UserDoesNotHavePermissionForTheFeatureRequested = 7,
    UserDoesnotHavePermissionForTheResourceRequested = 8,
    OnlyCustomerSSOSupported = 9,
    NonExistentResourceRequested = 10,
    AccountInModeration = 11,
    PendingJobseekerLoginRequest = 12,
    DataExpired = 13,
    CustomerIdNumberExists = 14,
    CvDatabaseAccessTokenRequestAlreadyProcessed = 15,
    SliderBannerRequestAlreadyProcessed = 16,
    CvBoxAccessTokenRequestAlreadyProcessed = 17,
    InvalidData = 18,
    CustomerNotInPortfolio = 19,
    CustomerNoBillingInfo = 20,
    TransactionRequestPayerPortfolioAccountManagerMismatch = 21,
    CustomerSigninAttemptOnWrongWebsite = 22,
    PendingUpgradeExists = 23,
    ActiveOrPendingTokenExists = 24,

    MatchingAlreadyProcessing = 25,
    AlreadyApproved = 26,
    NotAllJobseekersProcessed = 27,
    NoAcceptedJobseekers = 28,

    JobseekerSubscription_MandatoryFieldMissing = 29,

    NotificationDoesNotExist = 30,
    NotificationStatusNotValid = 31,
    NotifiedInPast = 32,

    JobseekerIsInModeration = 33,

    //intenral exceptions

    SubscriberNotFound = 34,
    SubscriberIsNotActiveOrPending = 35,
    HashCodeNotFound = 36,
    InvalidHashcodeType = 37,
    InvalidSubscriberCode = 38,

    CustomerAlreadyHasGotCBvox = 39,//TODO: rename
    InactiveCvBox = 40,
    JobseekerAlreadyApplied = 41,

    CustomerStatusCannotBeUndefined = 42,

    CustomerHasNotLogo = 43,
    JobseekerIsAlreadySaved = 44,

    AnnouncementDistributionChannelsDuplication = 45,

    MatchingListNotEmpty = 46,
    MatchingListEmpty = 47,

    InvalidAnnouncementMatchingJobseekerApproveRequestData = 48,

    AnnouncementLanguageAlreadyExists = 49,

    DeactivatedCustomerCantBeProfileOwner = 50,

    NoExpressApproveableJobseekers = 51,

    JobseekerEducationNotFound = 52,
    JobseekerWorkexperienceNotFound = 53,
    JobseekerLanguageNotFound = 54,
    JobseekerDocumentNotFound = 55,

    JobseekerEducationFileNotFound = 56,
    JobseekerWorkexperienceFileNotFound = 57,
    JobseekerLanguageFileNotFound = 58,
    JobseekerDocumentFileNotFound = 59,

    EmailVerificationTokenInvalid = 60,
    EmailVerificationResendMaxAttemptsExceeded = 61,
    UserAlreadyActivated = 62,

    InputValidationFailed = 63,
    CaptchaInvalid = 64,
    CaptchServiceError = 65,

    AnnouncementNotFound = 66,

    JobseekerCurrentPasswordIsIncorrect = 67,
    SubscriberIsNotActive = 68,
    PasswordMismatch = 69,
    TooManyCustomers = 70,
    NonExistingcustomerInBlacklist = 71,

    UserRegistrationNotFound = 72,
    CustomerInModeration = 73,
    JobseekerPendingModerationPhotoEditIsNotAllowed = 74,
    JobseekerIsNotActive = 75,
    CustomerDoesNotExist = 76,
    CustomerIsNotActive = 77,

    ProfileIsPrivate = 78,
    ProfileIsIncomplete = 79,

    MalformedUploadRequest = 80,
    CustomerIsBlackListed = 81,
    TooManyItemsOnPage = 82,
    InvalidAnnouncement = 83,

    CustomerAgentIndicatedAsContactPersonInActiveAnnouncement = 84,
    RedisTransactionFailure = 85,

    EmailGenerationServiceError = 86,
    ApacheTikaDocumentIndexingServiceError = 87,
    ErrorOccuredWhenAddingAnnouncement = 88,

    SqlConnectionStringNotProvided = 89,

    SqlEmailConnectionStringNotProvided = 90,

    CustomerBrandingAlreadyExists = 91,
    AnnouncementAutoRenewalProductLatePurchase = 92,

    AnnouncementAutoRenewalIncompatibleProductUpgrade = 93,
    PendingAnnouncementInstantRenewalIsNotAllowed = 94,
    NonActiveAnnouncementInstantRenewalIsNotAllowed = 95,
    MoreThanOneInstantRenewalsPerDayIsNotAllowed = 96,

    PendingAnnouncementUpgradeIsNotAllowed = 97,
    AnnouncementInstantRenewalIncompatibleProductUpgrade = 98,
    ProductNotFound = 99,

    FolderNotFound = 100,
    FolderNumberLimitReached = 101,
    InvalidFolderName = 102,
    InvalidEmailAddress = 103,
    InvalidLocation = 104,
    InvalidIndustry = 105,
    InvalidToken = 106,
    JobseekerNotFound = 107,
    ViewingJobseekerNotPermitted = 108,
    EmailIsAlreadyRegistered = 109,
    JobseekerDoesNotHaveCvDatabaseAccess = 110,

    JobseekerReachedDemoLimitCvDatabaseAccess = 115,

    CustomerDoesNotHaveBranding = 116,
    CustomerBrandingAccessTokenRequestAlreadyProcessed = 117,
    OnlyActiveCustomerBrandingAccessTokenCanBeTerminated = 118,

    ActiveOrPendigCustomerBrandingAccessTokenExists = 119,

    AnnouncementDetailsDesktopBgImageSizeIsTooLarge = 120,
    AnnouncementDetailsMobileBgImageSizeIsTooLarge = 121,

    InvoiceDoesNotFound = 122,

    CustomerFeedDoesNotFound = 123,
    CustomerWidgetDoesNotFound = 124,
    CustomerDoesNotHaveCustomFeed = 125,
    CreateCustomFeedDoesNotAllowed = 126,
    CustomerDoesNotAllowToViewFeed = 127,
}
