import { InjectionToken } from '@angular/core';
import { Injectable } from '@angular/core';
import { ApiEndpointConfig, ApiUrlConfig, } from '@hrra/core';
import * as P from 'pino';
import { LogginService } from '@hrra/core';


//let apiBase = '/../api/v2/';

export interface ApiEndpoint {
  url: string;
  analyticUrl: string;
}


export interface UrlConfig {

  customer: {
    get: string;
    getList: string;
    cvBoxApply: string;
  };

  announcements: {
    list: string;
    favorites: string;
    getRefreshedFavoriteIds: string;
    search: string;
    get: string;
    getSearchField: string;
    apply: string;
    announcementView: string;
  };

  jobseekers: {
    list: string;
    get: string;

    downloadDocument: string;
    downloadLanguageAttachment: string;
    downloadEducationAttachment: string;
    downloadExperienceAttachment: string;

    edit: string;

    validateEmail: string;
    register: string;
    resendActivationEmail: string;
    verifyEmail: string;
    uploadTempFile: string;

    privacy: string;
    changePassword: string;
    blacklist: string;

    resetPassword: string;

    subscription: {
      get: string;
      edit: string;
    };

    cvExport: string;
    applications: string;
    cvBoxApplications: string;
  };

  subscribers: {
    get: string;
    edit: string;
    validateEmail: string;
    subscribe: string;
    unsubscribe: string;
    resendActivationEmail: string;
    verifyEmail: string;
  };

  cvBox: {
    list: string;
  };

  auth: {
    login: string;
    logout: string;
    refreshAccessToken: string;
    getUserInfo: string;
    customerSignIn: string;
  };

  lookup: {
    common: string;
    partnerSites: string;
    providerWebsite: string;
    jobseekerLookup: string;
  };

  configs: {
    get: string;
  };

  infoPage: {
    get: string;
  };

  seoData: {
    cvRegistrationPage: string;
    subscriptionPage: string;
  };

  location: {
    get: string;
    getLocationTree: string;
  };
  banner: {
    insertClick: string,
    getList: string,
  }

}

export interface AnalyticUrlConfig {
  banner: {
    insertClick: string,
    bannerView: string,
  };
  announcement: {
    announcementView: string
  }

}

export let analyticUrlConfig: AnalyticUrlConfig = {
  banner: {
    insertClick: 'banner/click',
    bannerView: 'banner/view'
  },
  announcement: {
    announcementView: 'announcement/view'
  }
};

export let urlConfig: UrlConfig = {

  customer: {
    get: 'customer/{id}',
    getList: 'companies',
    cvBoxApply: 'cvbox-apply',
  },

  announcements: {
    list: 'announcements-main',
    favorites: 'announcement-favorites',
    getRefreshedFavoriteIds: 'refresh-favorites',
    search: 'announcement-search',
    get: 'announcement/{id}',
    getSearchField: 'search-field',
    apply: 'apply',
    announcementView: 'announcement-view',
  },

  jobseekers: {
    list: '/jobseekers',
    get: 'jobseeker/profile',

    downloadDocument:
      'jobseeker/profile/document/{jobseekerDocumentId}/attachment',
    downloadLanguageAttachment:
      'jobseeker/profile/language/{jobseekerLanguageId}/attachment',
    downloadEducationAttachment:
      'jobseeker/profile/education/{jobseekerEducationId}/attachment',
    downloadExperienceAttachment:
      'jobseeker/profile/workexperience/{jobseekerWorkExperienceId}/attachment',

    edit: 'jobseeker/profile',

    validateEmail: 'jobseeker/validate-email',
    register: 'jobseeker/register',
    resendActivationEmail: 'jobseeker/resend-activation-email',
    verifyEmail: 'jobseeker/verify-email',
    uploadTempFile: 'jobseeker/temp-upload',

    privacy: 'jobseekersettings/privacy',
    changePassword: 'jobseekersettings/change-password',
    blacklist: 'jobseekersettings/blacklist',

    resetPassword: 'jobseeker/reset-password',

    subscription: {
      get: 'jobseeker/subscription',
      edit: 'jobseeker/subscription',
    },

    cvExport: 'jobseeker/cv-export',
    applications: 'jobseeker/application-list',
    cvBoxApplications: 'jobseeker/cv-box-application-list',
  },

  subscribers: {
    get: 'subscriber/subscription',
    edit: 'subscriber/subscription',
    subscribe: 'subscriber/subscribe',
    unsubscribe: 'subscriber/unsubscribe',

    verifyEmail: 'subscriber/verify-email',
    validateEmail: 'subscriber/validate-email',
    resendActivationEmail: 'subscriber/resend-activation-email',
  },

  cvBox: {
    list: 'cvbox',
  },

  auth: {
    login: 'auth/signin',
    logout: 'auth/signout',
    refreshAccessToken: 'auth/refresh-access-token',
    getUserInfo: 'auth/get-user-info',
    customerSignIn: 'auth/customer-signin',
  },

  lookup: {
    common: 'common/lookup',
    partnerSites: 'partnerSites',
    providerWebsite: 'common/provider-website',
    jobseekerLookup: 'common/jobseeker-lookup',
  },

  configs: {
    get: 'configs',
  },

  infoPage: {
    get: 'info/{alias}',
  },

  seoData: {
    cvRegistrationPage: 'cv-registration-page-seo-data',
    subscriptionPage: 'subscription-page-seo-data',
  },

  location: {
    get: 'location/locations',
    getLocationTree: 'location/GetLocationTree',
  },
  banner: {
    insertClick: "insert-click",
    getList: "get-banners"
  }
};



export interface UrlConfigContainer extends ApiEndpointConfig
{
  api: ApiUrlConfig<UrlConfig>;
  analytics: ApiUrlConfig<AnalyticUrlConfig>;
}


export const newUrlConfig: UrlConfigContainer = 
{
  api: {
    apiType: "public-web-api",
    data: urlConfig
  },
  analytics: {
    apiType: "analytics-api",
    data: analyticUrlConfig
  }
};
