import { createAction, props } from '@ngrx/store';
import { User } from '../user/shared/models/user-model';
import { ProviderWebsite } from '../shared/models/provider-website';
import { Config } from '../shared/models/system-config-model';
import { ActionPayload } from "@hrra/core";
import { AnnouncementFilter } from 'src/app/shared/models/announcement-filter'
import { LookupState, ApplicationMessage } from './state'
import { ApiError } from '@hrra/core'
import { CommonLookupData } from '../shared/services/lookup.service'
import { AuthResult, LoginRequest, UserData, CurrentUserInfo } from '../core/auth-result';
import { PageNameEnum } from '../shared/models/enums/page-names-enum';
import { PartnerSitesModel } from '../shared/models/partner-sites-mode';
import { BannerHeaderPositionId } from '../shared/models/enums/banner-header-position-id-enum';
import { BannerIds } from '../banner/shared/models/banner-model';

export enum PartnerSitesLookupActions {
    LoadPartnerSitesLookup = '[App bootstrap] Load partner sites lookup',
    LoadPartnerSitesLookupSuccess = '[App bootstrap] Load partner sites lookup succsess',
    LoadPartnerSitesLookupFail = '[App bootstrap] Load partner sites lookup failed'
}

export const loadPartnerSitesLookups = createAction(
    PartnerSitesLookupActions.LoadPartnerSitesLookup
);

export const loadPartnerSitesSuccess = createAction(
    PartnerSitesLookupActions.LoadPartnerSitesLookupSuccess,
    props<{ payload: PartnerSitesModel[] }>()
);

export const loadPartnerSitesLookupsFailed = createAction(
    PartnerSitesLookupActions.LoadPartnerSitesLookupFail,
    props<{ payload: any }>()
);



//WebsiteInfoState
export enum ConfigsActions {
    LoadConfigs = '[App bootstrap] Load configs',
    LoadConfigsSuccess = '[App bootstrap] Load configs succsess',
    LoadConfigsFail = '[App bootstrap] Load configs failed'
}

export const loadConfigs = createAction(
    ConfigsActions.LoadConfigs
);

export const loadConfigsSuccess = createAction(
    ConfigsActions.LoadConfigsSuccess,
    props<{ payload: Config }>()
);

export const loadConfigsFail = createAction(
    ConfigsActions.LoadConfigsFail,
    props<{ payload: any }>()
);


//TODO:  currentInfo
export enum CurrentInfoActions {
    LoadCurrentInfo = '[App bootstrap] Load current info',
    LoadCurrentInfoSuccess = '[App bootstrap] Load current info success',
    LoadCurrentInfoFail = '[App bootstrap] Load current info failed',

    GetGuid = '[App bootstrap] get guid for anonymous',

    ChangeLanguage = '[Website Header] Change Language',
    LoadLanguage = '[App bootstrap] Load Language',
    LoadLanguageSuccess = '[App bootstrap] Load Language success',
    LoadLanguageFail = '[App bootstrap] Load Language fail',

    LastListingPage = '[App bootstrap] Last listing page',

    SetCurrentDate = '[App bootstrap] Set current date',

    LoadProviderWebsiteInfo = '[App bootstrap] Load provider website info',
    LoadProviderWebsiteInfoSuccess = '[App bootstrap] Load provider website info success',
    LoadProviderWebsiteInfoFail = '[App bootstrap] Load provider website info fail',
}

export const loadCurrentInfo = createAction(
    CurrentInfoActions.LoadCurrentInfo
);

export const getGuid = createAction(
    CurrentInfoActions.GetGuid
)

export const loadCurrentInfoSuccess = createAction(
    CurrentInfoActions.LoadCurrentInfoSuccess,
    props<{ payload: { currentLanguage: string, currenWbeiste: ProviderWebsite, isSignedIn: boolean, user: User } }>()
);

export const loadCurrentInfoFail = createAction(
    CurrentInfoActions.LoadCurrentInfoFail,
    props<{ payload: any }>()
);

export const changeLanguage = createAction(
    CurrentInfoActions.ChangeLanguage,
    props<ActionPayload<{ languageId: string}>>()
);

export const loadLanguage = createAction(
    CurrentInfoActions.LoadLanguage
);

export const loadLanguageSuccess = createAction(
    CurrentInfoActions.LoadLanguageSuccess,
    props<ActionPayload<{ languageId: string}>>()
);

export const loadLanguageFail = createAction(
    CurrentInfoActions.LoadLanguageFail
);

export const loadLastListingPage = createAction(
    CurrentInfoActions.LastListingPage,
    props<{ payload: PageNameEnum }>()
);

export const loadProviderWebsiteInfo = createAction(
    CurrentInfoActions.LoadProviderWebsiteInfo
);

export const loadProviderWebsiteInfoSuccess = createAction(
    CurrentInfoActions.LoadProviderWebsiteInfoSuccess,
    props<ActionPayload<{ providerWebsite: ProviderWebsite}>>()
);

export const loadProviderWebsiteInfoFail = createAction(
    CurrentInfoActions.LoadProviderWebsiteInfoFail,
    props<ActionPayload<ApiError>>()
);

export const setCurrentDate = createAction(
    CurrentInfoActions.SetCurrentDate,
    props<{ payload: {date: Date} }>()
);

export enum Actions {
    LoadCommonLookups = '[App bootstrap] Load common lookups',
    CommonLookupsLoaded = '[App bootstrap] Common lookups loaded',
    CommonLookupsLoadFailed = '[App bootstrap] Common lookups load failed',
}

export enum FacebookSdkActions {
    SdkInit = '[App bootstrap] Facebook sdk init',
    SdkInitSuccess = '[App bootstrap] Facebook sdk init success',
    SdkInitFail = '[App bootstrap] Facebook sdk init failed',
    ShowCustomerChatDialog = '[Facebook sdk] Show customer chat',
    HideCustomerChatDialog = '[Facebook sdk] Hide customer chat dialog',
    HideCustomerChat = '[Facebook sdk] Hide customer chat'
}

export const facebookSdkInit = createAction(
    FacebookSdkActions.SdkInit
);

export const facebookSdkInitSuccess = createAction(
    FacebookSdkActions.SdkInitSuccess
);

export const facebookSdkInitFail = createAction(
    FacebookSdkActions.SdkInitFail,
    props<ActionPayload<{ message: string }>>()
);

export const showCustomerChatDialog = createAction(
    FacebookSdkActions.ShowCustomerChatDialog
);

export const hideCustomerChatDialog = createAction(
    FacebookSdkActions.HideCustomerChatDialog
);

export const hideCustomerChat = createAction(
    FacebookSdkActions.HideCustomerChat
);

export const loadCommonLookups = createAction(
    Actions.LoadCommonLookups
)

export const commonLookupsLoaded = createAction(
    Actions.CommonLookupsLoaded,
    props<ActionPayload<CommonLookupData>>()
)

export const commonLookupsloadFailed = createAction(
    Actions.CommonLookupsLoadFailed,
    props<ActionPayload<ApiError>>()
)



//login -> login success -> 

export enum AuthActions {
    Login = '[Login page] Login request',
    LoginSuccess = '[Login page] Login success',
    LoginFailed = '[Login page] Login failed',
    LoginValidation = '[Login page] Login validation',
    LoginRedirectSuccess = '[Login page] Login redirect succes',
    ClearLoginPageInfo = '[Login page] Clear login page info',

    Logout = '[User profile] Log out',
    LogoutSuccess = '[User profile] Log out success',
    LogoutFailed = '[User profile] Log out failure',
    LogoutRedirectSuccess = '[User profile] Log out redirect success',

    GetCurrenUserInfo = '[Application bootstrap] Get current user info',
    GetCurrenUserInfoSuccess = '[Application bootstrap] Get current user info success',
    GetCurrenUserInfoFailure = '[Application bootstrap] Get current user info failure',


    SessionExpired = '[Auth session manager] Session expired',
    SessionEnded = '[Auth session manager] Session ended',

    SessionAltered = '[Local storage external event] Session altered',

    // GetStoredAccessToken = '[Auth session manager] Get stored access token',
    // GetStoredAccessTokenSuccess = '[Auth session manager] Get stored access token',
    // GetStoredAccessTokenFailure = '[Auth session manager] Get stored access token',

    GetNewAccessToken = '[Auth session manager] Refresh access token',//dispatched on app startup, and by expire timer
    GetNewAccessTokenSuccess = '[Auth session manager] Refresh access token success',//reset timer
    GetNewAccessTokenFailure = '[Auth session manager] Refresh access token failure',

    AuthInit = '[Application bootstrap] Auth init',//not needed????? just dispatch getNewAccessToken action
}

export enum SnackBarActions
{
    DisplaySnackBarMessage = '[General] Show snackbar message',
    CloseSnackBarMessage = '[General] Close snackbar message'
}

export const login = createAction(AuthActions.Login, props<ActionPayload<LoginRequest>>());
export const loginSuccess = createAction(AuthActions.LoginSuccess, props<ActionPayload<AuthResult>>());
export const loginFailure = createAction(AuthActions.LoginFailed, props<ActionPayload<ApiError>>());//
export const loginValidation = createAction(AuthActions.LoginValidation, props<ActionPayload<{ credentialsInvalid: boolean }>>());
export const clearLoginPageInfo = createAction(AuthActions.ClearLoginPageInfo);

//done
export const logout = createAction(AuthActions.Logout);
export const logoutSuccess = createAction(AuthActions.LogoutSuccess);
export const logoutFailure = createAction(AuthActions.LogoutFailed, props<ActionPayload<ApiError>>());
export const logoutRedirectSuccess = createAction(AuthActions.LogoutRedirectSuccess);
export const loginRedirectSuccess = createAction(AuthActions.LoginRedirectSuccess);

export const sessionEnded = createAction(AuthActions.SessionEnded);
export const sessionExpired = createAction(AuthActions.SessionExpired);

//done
export const getNewAccessToken = createAction(AuthActions.GetNewAccessToken);
export const getNewAccessTokenSuccess = createAction(AuthActions.GetNewAccessTokenSuccess, props<ActionPayload<AuthResult>>());
export const getNewAccessTokenFailure = createAction(AuthActions.GetNewAccessTokenFailure, props<ActionPayload<ApiError>>());

//check
// export const getStoredAccessToken  = createAction(AuthActions.GetStoredAccessToken);
// export const getStoredAccessTokenSuccess = createAction(AuthActions.GetStoredAccessTokenSuccess, props<ActionPayload<AuthResult>>());
// export const getStoredAccessTokenFailure = createAction(AuthActions.GetStoredAccessTokenFailure);

//done
export const getCurrentUserInfo = createAction(AuthActions.GetCurrenUserInfo);
export const getCurrenUserInfoSuccess = createAction(AuthActions.GetCurrenUserInfoSuccess, props<ActionPayload<CurrentUserInfo>>());
export const getCurrenUserInfoFailure = createAction(AuthActions.GetCurrenUserInfoFailure, props<ActionPayload<ApiError>>());


//snackbar

export const displaySnackBarMessage = createAction(SnackBarActions.DisplaySnackBarMessage, props<ActionPayload<ApplicationMessage>>());
export const closeSnackBarMessage = createAction(SnackBarActions.CloseSnackBarMessage);


export enum FavoritesActions {
    LoadFavorites = '[Favorites] Load favorites',
    LoadFavoritesSuccess = '[Favorites] Load favorites success',
    LoadFavoritesFail = '[Favorites] Load favorites fail',

    AddToFavorites = '[Favorites] Add to favorites',
    AddToFavoritesSuccess = '[Favorites] Add to favorites success',
    AddToFavoritesFail = '[Favorites] Add to favorites fail',

    RemoveFromFavorites = '[Favorites] Remove from favorites',
    RemoveFromFavoritesSuccess = '[Favorites] Remove from favorites success',
    RemoveFromFavoritesFail = '[Favorites] Remove from favorites fail'
}

export const loadFavorites = createAction(FavoritesActions.LoadFavorites);

export const loadFavortiesSuccess = createAction(
    FavoritesActions.LoadFavoritesSuccess,
    props<ActionPayload<{announcementIds: number[]}>>()
);

export const loadFavoritesFail = createAction(
    FavoritesActions.LoadFavoritesFail,
    props<ActionPayload<any>>()
);

export const addToFavorites = createAction(
    FavoritesActions.AddToFavorites,
    props<ActionPayload<{announcementId: number}>>()
);

export const addToFavoritesSuccess = createAction(
    FavoritesActions.AddToFavoritesSuccess,
    props<ActionPayload<{updatedFavoriteAnnouncementIds: number[]}>>()
);

export const addToFavoritesFail = createAction(
    FavoritesActions.AddToFavoritesFail,
    props<ActionPayload<any>>()
);



export const removeFromFavorites = createAction(
    FavoritesActions.RemoveFromFavorites,
    props<ActionPayload<{announcementId: number}>>()
);

export const removeFromFavoritesSuccess = createAction(
    FavoritesActions.RemoveFromFavoritesSuccess,
    props<ActionPayload<{updatedFavoriteAnnouncementIds: number[]}>>()
);

export const removeFromFavoritesFail = createAction(
    FavoritesActions.RemoveFromFavoritesFail,
    props<ActionPayload<any>>()
);

export enum AnalyticActions {
    SendBannerViewToAnalytic = '[App banner] send banner view to analytic'
}

export const sendBannerViewToAnalytic = createAction(
    AnalyticActions.SendBannerViewToAnalytic,
    props<ActionPayload<{bannerIdList: BannerIds,pageId: BannerHeaderPositionId, providerWebsiteId:number}>>()
)


