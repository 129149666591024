import { JobseekerProfileState } from "./state";
import { createImmerReducer } from 'ngrx-immer/store';
import * as JobseekerProfileActions from './actions';
import { on, Action } from '@ngrx/store';
import { JobseekerEducationModel } from 'src/app/jobseeker/shared/services/jobseeker.service';
import * as RootActions from "../../actions";
import { state } from "@angular/animations";
import { EducationLevelEnum } from "@hrra/common-models";

let initialState: JobseekerProfileState = {
    jobseeker: null,
    loading: false,
    updateLoading: false,
    attachmentLoading: false,
    fileUploading: false,
    file: null,
    isFileSizeValid: true,

    educationUiState: {
        selectedJobseekerEducation: null,
        isInstitutionSelectModeOn: null,
        isProgramSelectModeOn: null,
    },

    workExperienceUiState: {
        workExperience: null,
        currentSelectedCategories: [],
        isCurrent: false
    },
    
    photoState: {
        isPhotoSelected: false,
        isPhotoApproved: false,
        base64: null
    },

    personalInfoState: {
        selectedMonth: null,
        selectedYear: null,
        selectedAddress: null,
        selectedLegalAddress: null,
        selectedSameAsCurrentAddress: null
    },
    
    cvExportState: {
        loading: false,
        cvExportFailureDetails: null
    }
}

const reducer = createImmerReducer(
    initialState,
    on(JobseekerProfileActions.loadJobseekerProfile, state =>{
        state.loading = true;
        return state;
    }),
    on(JobseekerProfileActions.loadJobseekerProfileSuccess, (state, {payload}) => {
        state.jobseeker = payload;
        state.personalInfoState.selectedMonth = payload.birthMonth;
        state.personalInfoState.selectedYear = payload.birthYear;
        state.personalInfoState.selectedAddress = payload.address;
        state.personalInfoState.selectedLegalAddress = payload.legalAddress;
        state.personalInfoState.selectedSameAsCurrentAddress = payload.sameAsCurrentAddress;
        state.loading = false;
        return state;
    }),
    on(JobseekerProfileActions.loadJobseekerProfileFail, (state, {payload}) => {
        state.loading = false;
        return state;
    }),

    on(RootActions.logoutSuccess, (state, _) => {
        Object.assign(state, initialState);
        return state;
    }),

    //delete
    on(JobseekerProfileActions.deleteJobseekerDocument, (state, {payload}) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerDocumentSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerDocumentFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerLanguage, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerLanguageSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerLanguageFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerEducation, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerEducationSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerEducationFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerWorkExperience, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerWorkExperienceSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerWorkExperienceFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    on(JobseekerProfileActions.deleteJobseekerPhoto, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerPhotoSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.deleteJobseekerPhotoFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),


    //downloads
    on(JobseekerProfileActions.downloadEducationAttachment, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadEducationAttachmentSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadEducationAttachmentFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadLanguageAttachment, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadLanguageAttachmentSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadLanguageAttachmentFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadDocumentAttachment, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadDocumentAttachmentSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadDocumentAttachmentFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadWorkExperienceAttachment, state => {
        state.attachmentLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.downloadWorkExperienceAttachmentSuccess, state => {
        state.attachmentLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.downloadWorkExpeienceAttachmentFail, (state, {payload}) => {
        state.attachmentLoading = false;
        return state;
    }),


    //edit jobseeker
    on(JobseekerProfileActions.editJobseekerPersonalInfo, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPersonalInfoSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPersonalInfoFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    
    on(JobseekerProfileActions.editJobseekerEducation, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerEducationSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerEducationFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerEducation, (state, {payload}) => {
        if(payload.educationId == null){
            let x: JobseekerEducationModel = <any>{};
            
            x.jobseekerEducationId = null;
            x.educationLevelId = null;
            x.educationalInstitutionId = null;
            x.educationalProgramId = null;
            x.educationalProgramName = null;
            x.institution = null;
            x.specialization = null;
            x.dateFromYear = null;
            x.dateFromMonth = null;
            x.dateToYear = null;
            x.dateToMonth = null;
            x.description = null;
            x.attachment = null;

            state.educationUiState.selectedJobseekerEducation = x;
        }

        else{
            let education = state.jobseeker.pendingModifiedJobseekerEducations.find(e => e.jobseekerEducationId == payload.educationId) ||
            state.jobseeker.jobseekerEducations.find(e => e.jobseekerEducationId == payload.educationId);

            let x: JobseekerEducationModel = <any>{};
            x.jobseekerEducationId = education?.jobseekerEducationId;
            x.educationLevelId = education?.educationLevel?.educationLevelId;
            x.educationalInstitutionId = education?.educationalInstitution?.educationalInstitutionId;
            x.educationalProgramId = education?.educationalProgram?.educationalProgramId;
            x.educationalProgramName = education?.educationalProgramName;
            x.institution = education?.institution;
            x.specialization = education?.specialization;
            x.dateFromYear = education?.dateFromYear;
            x.dateFromMonth = education?.dateFromMonth;
            x.dateToYear = education?.dateToYear;
            x.dateToMonth = education?.dateToMonth;
            x.description = education?.description;
            x.attachment = education?.attachment;

            state.educationUiState.selectedJobseekerEducation = x;

            state.educationUiState.isInstitutionSelectModeOn = education.educationalInstitution != null;
            state.educationUiState.isProgramSelectModeOn = education.educationalProgram != null;
        }

        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerEducationLevel, (state, {payload}) => {
        state.educationUiState.selectedJobseekerEducation.educationLevelId = payload.levelId;
        if(payload.levelId == EducationLevelEnum.Bachelor || payload.levelId == EducationLevelEnum.Master || payload.levelId == EducationLevelEnum.Doctor || payload.levelId == EducationLevelEnum.College){
            state.educationUiState.isInstitutionSelectModeOn = true;
            state.educationUiState.isProgramSelectModeOn = true;
        }
        else{
            state.educationUiState.isInstitutionSelectModeOn = false;
            state.educationUiState.isProgramSelectModeOn = false;
        }
        return state;
    }),
    on(JobseekerProfileActions.institutionSelectMode, state => {
        state.educationUiState.isInstitutionSelectModeOn = !state.educationUiState.isInstitutionSelectModeOn;
        if(!state.educationUiState.isInstitutionSelectModeOn){
            state.educationUiState.isProgramSelectModeOn = false;
        }
        return state;
    }),
    on(JobseekerProfileActions.programSelectMode, state => {
        let programMode = state.educationUiState.isProgramSelectModeOn;
        state.educationUiState.isProgramSelectModeOn = !programMode;
        if(!programMode){
            state.educationUiState.isInstitutionSelectModeOn = true;
        }
        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerEdcuationalInstitution, (state, {payload}) => {
        state.educationUiState.selectedJobseekerEducation.educationalInstitutionId = payload.institutionId;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerEditEducationForm, state => {
        state.educationUiState.selectedJobseekerEducation = null;
        state.educationUiState.isProgramSelectModeOn = null;
        state.educationUiState.isInstitutionSelectModeOn = null;
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),

    on(JobseekerProfileActions.editJobseekerLanguage, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerLanguageSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerLanguageFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerEditLanguageForm, state => {
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerWorkExperience, state => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerWorkExperienceSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerWorkExperienceFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.setJobseekerWorkExperience, (state, { payload }) => {
        state.workExperienceUiState.workExperience = payload;

        if (payload != null){
            state.workExperienceUiState.currentSelectedCategories = payload?.categories.map(e => e.category.id);
            state.workExperienceUiState.isCurrent = payload?.isCurrent;
        }
        
        return state;
    }),
    on(JobseekerProfileActions.setSelectedCategories, (state, { payload }) => {
        state.workExperienceUiState.currentSelectedCategories = payload;
        return state;
    }),
    on(JobseekerProfileActions.closeWorkExperienceEditForm, state => {
        state.workExperienceUiState.currentSelectedCategories = [];
        state.workExperienceUiState.isCurrent = false;
        state.workExperienceUiState.workExperience = null;
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.changeWorkExperienceIsCurrent, state => {
        state.workExperienceUiState.isCurrent = !state.workExperienceUiState.isCurrent;
        return state;
    }),
    on(JobseekerProfileActions.setIsWorkExperienceFilled, (state, { payload }) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.setIsWorkExperienceFilledSuccess, (state, { payload }) => {
        state.jobseeker = payload;
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.setIsWorkExperienceFilledFail, (state, { payload }) => {
        state.updateLoading = false;
        return state;
    }),

    //file upload
    on(JobseekerProfileActions.uploadTempFile, (state, {payload}) => {
        state.fileUploading = true;
        return state;
    }),
    on(JobseekerProfileActions.uploadTempFileSuccess, (state, {payload}) => {
        state.fileUploading = false;
        state.file = payload;
        return state;
    }),
    on(JobseekerProfileActions.uploadTempFileFail, (state, {payload}) => {
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerUploadDocumentForm, state => {
        state.file = null;
        state.isFileSizeValid = true;
        state.fileUploading = false;
        return state;
    }),
    on(JobseekerProfileActions.setFileSizeValidation, (state, { payload }) => {
        state.isFileSizeValid = payload;
        if(!payload){
            state.file = null
        }
        return state;
    }),

    //document upload
    on(JobseekerProfileActions.uploadJobseekerDocument, (state, {payload}) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.uploadJobseekerDocumentSuccess, (state, {payload}) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.uploadJobseekerDocumentFail, (state, {payload}) => {
        state.updateLoading = false;
        return state;
    }),

    //edit photo
    on(JobseekerProfileActions.editJobseekerPhoto, (state, { payload }) => {
        state.updateLoading = true;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPhotoSuccess, (state, { payload }) => {
        state.updateLoading = false;
        state.jobseeker = payload;
        return state;
    }),
    on(JobseekerProfileActions.editJobseekerPhotoFail, (state, { payload }) => {
        state.updateLoading = false;
        return state;
    }),
    on(JobseekerProfileActions.insertCroppedImage, (state, { payload }) => {
        state.photoState.base64 = payload.base64;
        return state;
    }),
    on(JobseekerProfileActions.selectJobseekerPhoto, state => {
        state.photoState.isPhotoSelected = true;
        return state;
    }),
    on(JobseekerProfileActions.closeJobseekerPhotoEditForm, state => {
        state.photoState.isPhotoApproved = false;
        state.photoState.isPhotoSelected = false;
        state.photoState.base64 = null;
        return state;
    }),
    on(JobseekerProfileActions.approveJobseekerPhoto, state => {
        state.photoState.isPhotoApproved = true;
        state.photoState.isPhotoSelected = false;
        return state;
    }),
    on(JobseekerProfileActions.setSelectedBirthMonth, (state, { payload }) => {
        state.personalInfoState.selectedMonth = payload;
        return state;
    }),
    on(JobseekerProfileActions.setSelectedBirthYear, (state, { payload }) => {
        state.personalInfoState.selectedYear = payload;
        return state;
    }),
    on(JobseekerProfileActions.updateJobseekerAddress, (state, {payload}) => {
        state.personalInfoState.selectedAddress = payload;
        return state;
    }),
    on(JobseekerProfileActions.updateSameAsCurrentAddress, (state, {payload}) => {
        state.personalInfoState.selectedSameAsCurrentAddress = payload;
        return state;
    }),
    on(JobseekerProfileActions.updateJobseekerLegalAddress, (state, {payload}) => {
        state.personalInfoState.selectedLegalAddress = payload;
        return state;
    }),

    //cvExport
    on(JobseekerProfileActions.jobseekerCvExport, state => {
        state.cvExportState.loading = true;
        return state;
    }),
    on(JobseekerProfileActions.jobseekerCvExportSuccess, state => {
        state.cvExportState.loading = false;
        return state;
    }),
    on(JobseekerProfileActions.jobseekerCvExportFailed, (state, { payload }) => {
        state.cvExportState.loading = false;
        state.cvExportState.cvExportFailureDetails = payload;
        return state;
    })
);

export function jobseekerProfileReducer(state: JobseekerProfileState | undefined, action: Action) {
    return reducer(state, action);
}

