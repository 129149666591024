import { ActionReducerMap, on, Action } from '@ngrx/store';
import { JobseekerStoreState, JobseekerLookupsState } from './state';
import { JobseekerProfileReducer } from './jobseeker-profile';
import { createImmerReducer } from 'ngrx-immer/store';
import * as JobseekerActions from './actions';
import { JobseekerRegistrationReducer } from './jobseeker-registration';
import { JobseekerSettingsReducer } from './jobseeker-settings';
import { categoryPickerReducer } from './category-picker/reducer';
import { JobseekerResetPasswordReducer } from './reset-password';
import { jobseekerSubscriptionReducer } from './jobseeker-subscription/reducer';
import { jobseekerApplicationsReducer } from './jobseeker-applies/reducer';

let initialState: JobseekerLookupsState ={
    lookup: null,
    loading: false,
    isLookupLoadingSuccessed: false
}

const reducer = createImmerReducer(
    initialState,
    on(JobseekerActions.loadJobseekerLookups, state => {
        state.loading = true;
        return state;
    }),
    on(JobseekerActions.loadJobseekerLookupsSuccess, (state, {payload}) => {
        state.lookup = payload
        state.loading = false;
        state.isLookupLoadingSuccessed = true;
        return state;
    }),
    on(JobseekerActions.loadJobseekersLookupsFailed, (state, {payload}) => {
        state.loading = false;
        return state;
    })
);

export function jobseekerLookupReducer(state: JobseekerLookupsState | undefined, action: Action) {
    return reducer(state, action);
}



export const reducers: ActionReducerMap<JobseekerStoreState> = {
    jobseekerLookup: jobseekerLookupReducer,
    jobseekerProfile: JobseekerProfileReducer.jobseekerProfileReducer,
    jobseekerRegistration: JobseekerRegistrationReducer.jobseekerRegistrationReducer,
    jobseekerSettings: JobseekerSettingsReducer.jobseekerSettingsReducer,
    categoryPicker: categoryPickerReducer,
    resetPassword: JobseekerResetPasswordReducer.jobseekerResetPasswordReducer,
    subscription: jobseekerSubscriptionReducer,
    applies: jobseekerApplicationsReducer
};
