import { Component, OnInit, Input } from '@angular/core';
import { theme } from 'src/environments/theme';
import { CvBox } from '../shared/models/cv-box-model';

@Component({
  selector: 'app-cv-box-item',
  templateUrl: `./${theme.name}/cv-box-item.component.html`,
  styleUrls: [`./${theme.name}/cv-box-item.component.scss`]
})
export class CvBoxItemComponent implements OnInit {

  @Input() cvBox: CvBox;
  isLogoWitBackground = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}
