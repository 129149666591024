import { Component, Inject, OnInit } from '@angular/core';
import { AnnouncementDetails } from '../shared/models/announcement-details-model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  apply,
  getAnnDetailsPageBannerList,
  loadAnnouncementDetails,
  loadNavigationIds,
  sendAnnouncementViewToAnalytic,
  showCvBoxSlider,
} from 'src/app/root-store/announcement-store/ann-details-page/actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  selectAnnouncementDetails,
  selectBackToSearchResultRouterLink,
  selectBackToSearchResultQueryParams,
  getNextAnnouncementId,
  getPreviousAnnouncementId,
  selectAnnouncementPublishDate,
  selectAnnouncementNotFound,
  selectSimilarAnnouncements,
  selectIsAnnouncementDetailsLoading,
  selectAnnouncementDetailsMetaData,
  selectAnnDetailsTopBanner,
  selectAnnDetailsRightBanner,
  selectAnnDetailsRightBanner2,
  selectAnnDetailsSquareBanner,
  selectAnnDetailsMiddleBanner,
  selectAnnDetailsBottomBanner,
  selectAnnDetailsStateInfo,
  selectIsAnnDetailsCvBoxSliderVisible,
  selectAnnouncementDesktopImage,
  selectAnnouncementMobileImage,
  ownAnnouncements,
  selectAnnDetailsRightBanner3,
  selectAnnDetailsPageBannerIds,
} from 'src/app/root-store/announcement-store/ann-details-page/selectors';
import { AnnouncementListingSectionEnum } from '@hrra/common-models';
import { ApplicationMethodEnum } from '@hrra/common-models';
import { User } from 'src/app/user/shared/models/user-model';
import * as selectors from 'src/app/root-store/selectors';
import { UserTypeEnum } from 'src/app/user/shared/models/enums/user-type-enum';
import { theme } from 'src/environments/theme';
import { PageNameEnum } from 'src/app/shared/models/enums/page-names-enum';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { filter, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SimilarAnnouncement } from '../shared/models/similar-announcement-model';
import {
  addToFavorites,
  loadLastListingPage,
  removeFromFavorites,
  sendBannerViewToAnalytic,
} from 'src/app/root-store/actions';
import { CvBox } from 'src/app/cv-box/shared/models/cv-box-model';
import {
  selectAllPremiumCvBoxes,
  selectShuffledPremiumCvBoxes,
} from 'src/app/root-store/cv-box-store/selectors';
import { loadFrontPageCvBoxes } from 'src/app/root-store/cv-box-store/actions/cv-box-front-page-actions';
import {
  selectIsCustomerSignedIn,
  selectIsJobseekerSignedIn,
  selectTimeZone,
  selectCurrency,
  selectCurrentLanguage,
  selectCurrentWebsite,
} from 'src/app/root-store/selectors';
import { MetaService } from 'src/app/shared/services/meta.service';
import { Banner } from 'src/app/banner/shared/models/banner-model';
import { BannerTargetEnum } from 'src/app/banner/shared/models/enums/banner-target-enum';
import { BannerPositionEnum } from 'src/app/banner/shared/models/enums/banner-position-enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ErrorTypeEnum } from '@hrra/ui';
import { Breakpoints } from 'src/app/shared/models/breakpoints-model';
import { SsrService } from 'src/app/core/ssr.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { getOwlOptions } from 'src/app/shared/helpers/cv-box-slider-otions ';
import { Announcement } from '../shared/models/announcement-model';
import { CurrentNavigation } from 'src/app/root-store/announcement-store/ann-details-page/state';
import { TranslitService } from 'src/app/shared/services/translit.service';
import { BannerHeaderPositionId } from 'src/app/shared/models/enums/banner-header-position-id-enum';

@Component({
  selector: 'app-ann-details-page',
  templateUrl: `./${theme.name}/ann-details-page.component.html`,
  styleUrls: [`./${theme.name}/ann-details-page.component.scss`],
})
export class AnnDetailsPageComponent implements OnInit {
  currentLanguage$: Observable<string>;

  metaDataSubscription: Subscription;

  pageId:any;

  target: any;
  targetId: number = BannerTargetEnum.Desktop;
  cvBoxSliderOptions: OwlOptions;

  public applicationMethod: any;
  public userType: any;
  public pageName: any;
  public errorType: any;

  public routerLink$: Observable<string>;
  public queryParams$: Observable<Params>;
  public nextAnnouncementId$: Observable<CurrentNavigation>;
  public previousAnnouncementId$: Observable<CurrentNavigation>;
  public publishDate$: Observable<Date>;
  public announcementNotFound$: Observable<boolean>;
  public timeZone$: Observable<string>;

  public lastListingPage: PageNameEnum;

  public currency$: Observable<string>;

  public listingSectionNameMap = {
    [AnnouncementListingSectionEnum.VipCV]: 'label.VipCv',
    [AnnouncementListingSectionEnum.Vip]: 'label.Vip',
    [AnnouncementListingSectionEnum.Exclusive]: 'label.Exclusive',
    [AnnouncementListingSectionEnum.P2]: 'label.PrioStandard',
    [AnnouncementListingSectionEnum.P1]: 'label.PrioStart',
  };

  announcementDetails$: Observable<AnnouncementDetails>;
  isSignedIn$: Observable<boolean>;
  isJobseekerSignedIn$: Observable<boolean>;
  isCustomerSignedIn$: Observable<boolean>;
  user$: Observable<User>;
  similarAnnouncementList$: Observable<SimilarAnnouncement[]>;
  favoriteAnnouncementsMap$: Observable<{ [index: number]: boolean }>;
  premiumCvBoxes$: Observable<CvBox[]>;

  isLoading$: Observable<boolean>;

  topBanner$: Observable<Banner>;
  rightBanner$: Observable<Banner>;
  rightBanner2$: Observable<Banner>;
  rightBanner3$: Observable<Banner>;
  rightBannerSquare$: Observable<Banner>;
  middleBanner$: Observable<Banner>;
  bottomBanner$: Observable<Banner>;

  isCvBoxSliderVisible$: Observable<boolean>;

  desktopImage$: Observable<string>;
  mobileImage$: Observable<string>;
  ownAnnouncements$: Observable<Announcement[]>;

  public announcementWorkExperienceEnumMap = {};

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private announcementService: AnnouncementService,
    private translate: TranslateService,
    private metaService: MetaService,
    private dialogService: DialogService,
    private ssr: SsrService,
    public translit: TranslitService
  ) {
    this.cvBoxSliderOptions = getOwlOptions();
    this.applicationMethod = ApplicationMethodEnum;
    (this.userType = UserTypeEnum);
      (this.pageName = PageNameEnum);
      (this.errorType = ErrorTypeEnum);
      (this.target = BannerTargetEnum);
      (this.targetId =
        window.innerWidth > Breakpoints.medium
          ? BannerTargetEnum.Desktop
          : BannerTargetEnum.Responsive);
          this.pageId = BannerHeaderPositionId
  }

  public onToggleFavorite(announcementId: number, isFavorite: boolean) {
    if (isFavorite) {
      this.store.dispatch(
        removeFromFavorites({ payload: { announcementId: announcementId } })
      );
    } else {
      this.store.dispatch(
        addToFavorites({ payload: { announcementId: announcementId } })
      );
    }
  }

  ngOnInit(): void {
    this.store.dispatch(loadLastListingPage({payload: PageNameEnum.AnnouncementDetails}));
    this.isCvBoxSliderVisible$ =  this.store.pipe(select(selectIsAnnDetailsCvBoxSliderVisible));

    this.currentLanguage$ = this.store.pipe(select(selectCurrentLanguage));

    if (this.ssr.isPlatformBrowser()) {
      this.store.dispatch(
        getAnnDetailsPageBannerList({
          payload: {
            targetId: this.targetId,
            bannerHeadId: BannerPositionEnum.Announcement_details,
          },
        })
      );
      this.store.dispatch(showCvBoxSlider({ payload: true }));
    }

    let paramMap$ = this.route.paramMap;
    let annDetailsState$ = this.store.pipe(
      select(selectAnnDetailsStateInfo),
      filter((c) => c.pageSize !== undefined && c.isLookupLoaded),
      take(1)
    );
    let queryParamMap$ = this.route.queryParamMap;

    
    let annDetailsPageBanners$ = this.store.select(selectAnnDetailsPageBannerIds)
    let providerWebsiteId$ = this.store.select(selectCurrentWebsite)

    combineLatest([annDetailsPageBanners$,providerWebsiteId$])
    .pipe(
      filter(([banners, provderWebsite]) =>{
        return banners.bannerIdList.length > 0 && provderWebsite !== null && provderWebsite !== undefined;
      }),
      take(1)
    ).subscribe(
      ([b, p]) => {
        this.store.dispatch(sendBannerViewToAnalytic({payload:{bannerIdList: b, providerWebsiteId:p.providerWebsiteId, pageId: BannerHeaderPositionId.FrontPage}}))
      }
    )
    
    // this.route.paramMap.pipe(
    combineLatest([paramMap$, queryParamMap$, annDetailsState$])
    .subscribe(([p, q, s]) => {

      let id = +p.get('id');

        let urlTitle = p.has('title') ? p.get('title') : null;

        let payload = { announcementId: id, urlTitle: urlTitle };

      if(q.has('nav')){
        payload['nav'] = q.get('nav');
      }
        this.store.dispatch(loadAnnouncementDetails({ payload: payload }));

        this.store.dispatch(loadFrontPageCvBoxes());

        //previous/next announcement
        let directNavigation = !q.has('nav');

        if (s.lastListingPage == PageNameEnum.SearchResultsPage) {
          let start: number;
          let index: number;

          let tmpNavigationIds = s.search.announcements.map(
            (e) => e.announcementId
          );
          let itemsPerPage = s.pageSize;
          let currentPage = s.search.filter.currentPage;

          if (directNavigation) {
            start = (currentPage - 1) * itemsPerPage;
          }
          else {
            tmpNavigationIds = s.annDetails.currentNvaigationIds.map((values) => values.announcementId);
            start = s.annDetails.start;
          }

          index = tmpNavigationIds.indexOf(id);

          let isLast =
            (currentPage - 1) * itemsPerPage + index ==
            s.search.matchedAnnouncementCount - 1;

          if (index == tmpNavigationIds.length - 1 && !isLast) {
            let newStart = start + (itemsPerPage - 2);

            if (newStart > s.search.matchedAnnouncementCount - 1) {
              start = s.search.matchedAnnouncementCount - (itemsPerPage - 1);
            } else {
              start = newStart;
            }
          }

          if (index == 0) {
            let newStart = start - (itemsPerPage - 1);
            if (newStart < 0) {
              start = 0;
            } else {
              start = newStart;
            }
          }

          let filter = s.search.filter;
          let fullFilter = this.announcementService.convertFilterToFullFilter(
            filter,
            s.lookup,
            itemsPerPage
          );

          fullFilter.start = start;

          this.store.dispatch(
            loadNavigationIds({ payload: { filter: fullFilter, start: start } })
          );
        }

        this.metaDataSubscription = this.store
          .pipe(select(selectAnnouncementDetailsMetaData))
          .subscribe((data) => {
            this.metaService.updateMetaData(data?.website, data?.metaData);
          });
      }
    );

    this.announcementDetails$ = this.store.pipe(
      select(selectAnnouncementDetails)
    );

    this.isSignedIn$ = this.store.select(selectors.selectIsSignedIn);
    this.isJobseekerSignedIn$ = this.store.pipe(
      select(selectIsJobseekerSignedIn)
    );
    this.isCustomerSignedIn$ = this.store.pipe(
      select(selectIsCustomerSignedIn)
    );
    this.user$ = this.store.pipe(select(selectors.selectUser));

    this.routerLink$ = this.store.pipe(
      select(selectBackToSearchResultRouterLink)
    );
    this.queryParams$ = this.store.pipe(
      select(selectBackToSearchResultQueryParams)
    );

    this.nextAnnouncementId$ = this.store.pipe(select(getNextAnnouncementId));
    this.previousAnnouncementId$ = this.store.pipe(
      select(getPreviousAnnouncementId)
    );
    this.publishDate$ = this.store.pipe(select(selectAnnouncementPublishDate));

    this.similarAnnouncementList$ = this.store.pipe(
      select(selectSimilarAnnouncements)
    );
    this.announcementNotFound$ = this.store.pipe(
      select(selectAnnouncementNotFound)
    );

    this.favoriteAnnouncementsMap$ = this.store.pipe(
      select(selectors.selectFavoriteAnnouncementsMap)
    );

    this.premiumCvBoxes$ = this.store.pipe(
      select(selectShuffledPremiumCvBoxes)
    );

    this.isLoading$ = this.store.pipe(
      select(selectIsAnnouncementDetailsLoading)
    );

    this.timeZone$ = this.store.pipe(select(selectTimeZone));

    this.topBanner$ = this.store.pipe(select(selectAnnDetailsTopBanner));
    this.rightBanner$ = this.store.pipe(select(selectAnnDetailsRightBanner));
    this.rightBanner2$ = this.store.pipe(select(selectAnnDetailsRightBanner2));
    this.rightBanner3$ = this.store.pipe(select(selectAnnDetailsRightBanner3));
    this.rightBannerSquare$ = this.store.pipe(
      select(selectAnnDetailsSquareBanner)
    );
    this.middleBanner$ = this.store.pipe(select(selectAnnDetailsMiddleBanner));
    this.bottomBanner$ = this.store.pipe(select(selectAnnDetailsBottomBanner));

    this.desktopImage$ = this.store.pipe(
      select(selectAnnouncementDesktopImage)
    );
    this.mobileImage$ = this.store.pipe(select(selectAnnouncementMobileImage));
    this.ownAnnouncements$ = this.store.pipe(select(ownAnnouncements));

    this.currency$ = this.store.select(selectCurrency);

    this.announcementWorkExperienceEnumMap = {
      1: 'label.ExperienceNeeded',
      2: 'label.WithoutExperience',
      3: 'label.AnyExperience',
    };
  }

  ngOnDestroy() {
    this.metaDataSubscription.unsubscribe();
  }

  onCvSend(
    announcementId: number,
    isSignedIn: boolean,
    isJobseekerSignedIn: boolean
  ) {
    if (!isSignedIn) {
      let text = this.translate.instant(
        'message.ApplicantMustSignInToApplyToAnnouncement'
      );
      let routerText = this.translate.instant('label.SignIn');
      let routerLink = '/jobseeker/sign-in';

      this.dialogService.openExeptionDialog(text, routerText, routerLink);
    } else if (isJobseekerSignedIn) {
      this.dialogService
        .openConfirmDialog('message.AreYouSure')
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.store.dispatch(
              apply({ payload: { announcementId: announcementId } })
            );
          }
        });
    }
  }
}
