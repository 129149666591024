import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotExistingPageComponent } from './hr-ui/not-existing-page/not-existing-page.component';
import { JobseekerEmailVerificationComponent } from './jobseeker-email-verification/jobseeker-email-verification.component';

const routes: Routes = [
  {
    path: 'action/activate/:code',
    pathMatch: 'full',
    component: JobseekerEmailVerificationComponent,
  },
  {
    path: 'jobseeker',
    loadChildren: () =>
      import('./jobseeker/jobseeker.module').then((m) => m.JobseekerModule),
  },
  {
    path: 'subscriber',
    loadChildren: () =>
      import('./subscriber/subscriber.module').then((m) => m.SubscriberModule),
  },
  { path: '**', component: NotExistingPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
