import { Actions, createEffect, ofType } from '@ngrx/effects';
//import * as LookupActions from './actions';
import { LookupService } from '../shared/services/lookup.service';
import {
  catchError,
  mergeMap,
  map,
  concatMap,
  switchMap,
  tap,
  delay,
  delayWhen,
  filter,
  take,
} from 'rxjs/operators';
import { of, timer, Observable, NEVER, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { SystemConfigService } from '../shared/services/system-config.service';
import * as RootActions from './actions';
import { commonLookupsLoaded, commonLookupsloadFailed } from './actions';
import { AuthService } from '../core/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
//import * as moment from 'moment';
import * as dayjs from 'dayjs';
import { ActionPayload } from '@hrra/core';
import { AuthResult } from '../core/auth-result';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LocalDataStorageService } from '@hrra/core';
import { TranslateService } from '@ngx-translate/core';
import { FavoritesService } from '../shared/services/favorites.service';
import { AnnouncementService } from '../announcement/shared/services/announcement.service';
import { FacebookService } from '@hrra/integrations';
import { select, Store } from '@ngrx/store';
import { AppState } from './state';
import { selectSystemConfigs } from './selectors';
import { CustomSnackbarService } from '@hrra/ui';
import { create } from 'domain';
import { UniqueGuidService } from '../shared/services/unique-guid.service';
import { BannerService } from '../banner/shared/services/banner.service';

@Injectable()
export class CurrentWebsiteEffects {
  loadLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.Actions.LoadCommonLookups),
      mergeMap(() =>
        this.lookupService.getAll().pipe(
          map((data) => commonLookupsLoaded({ payload: data })),
          catchError((err) => of(commonLookupsloadFailed({ payload: err })))
        )
      )
    )
  );

  loadPartnerSitesForFooter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loadPartnerSitesLookups),
      mergeMap(() =>
        this.lookupService.getPartnerSites().pipe(
          map((sites) =>
            RootActions.loadPartnerSitesSuccess({ payload: sites.data })
          ),
          catchError((error) =>
            of(RootActions.loadPartnerSitesLookupsFailed({ payload: 'error' }))
          )
        )
      )
    )
  );

  loadSystemConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loadConfigs),
      mergeMap(() =>
        this.systemConfigService.getConfigs().pipe(
          map((socialMedias) =>
            RootActions.loadConfigsSuccess({ payload: socialMedias.data })
          ),
          catchError((error) =>
            of(RootActions.loadConfigsFail({ payload: 'error' }))
          )
        )
      )
    )
  );

  changeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.changeLanguage),
        tap((c) => this.localStorage.setUiLanguage(c.payload.languageId)),
        //tap(c => this.translate.use()) wihtout reload, all refernce dat
        tap(() => window.location.reload())
      ),
    { dispatch: false }
  );

  loadLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loadLanguage),
      map(() => this.localStorage.getUiLanguage() || 'ka'), //TODO portig from config
      tap((c) => this.localStorage.setUiLanguage(c)),
      mergeMap((c) =>
        this.translate.use(c).pipe(
          map(
            () =>
              RootActions.loadLanguageSuccess({ payload: { languageId: c } }),
            catchError(() => of(RootActions.loadLanguageFail()))
          )
        )
      ) //TODO get default language from config
    )
  );

  loadProviderWebsite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loadProviderWebsiteInfo),
      mergeMap(() =>
        this.lookupService.getProviderWebsite().pipe(
          map((action) =>
            RootActions.loadProviderWebsiteInfoSuccess({
              payload: { providerWebsite: action.data },
            })
          ),
          catchError((error) =>
            of(RootActions.loadProviderWebsiteInfoFail({ payload: error }))
          )
        )
      )
    )
  );

  fbSdkInit$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(RootActions.facebookSdkInit)),
      this.translate.get('label.HowCanIHelpYou'),
    ]).pipe(
      tap(([action, message]) => {
        try {
          this.facebookService.init();
        } catch (err) {
          console.log(err);
          throw err;
        }
      }),
      map(() => RootActions.facebookSdkInitSuccess()),
      catchError((err) =>
        of(RootActions.facebookSdkInitFail({ payload: { message: err } }))
      )
    )
  );

  fbCustomerChatDialogOpen$ = createEffect(
    () =>
      this.actions$.pipe(ofType(RootActions.facebookSdkInitSuccess)).pipe(
        take(1),
        switchMap(() =>
          this.actions$.pipe(ofType(RootActions.showCustomerChatDialog))
        ),
        tap(() => {
          this.facebookService.showCustomerChatDialog();
        })
      ),
    { dispatch: false }
  );

  fbCustomerChatDialogHide$ = createEffect(
    () =>
      this.actions$.pipe(ofType(RootActions.facebookSdkInitSuccess)).pipe(
        take(1),
        switchMap(() =>
          this.actions$.pipe(ofType(RootActions.hideCustomerChat))
        ),
        tap(() => {
          this.facebookService.hideCustomerChat();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private lookupService: LookupService,
    private systemConfigService: SystemConfigService,
    private localStorage: LocalDataStorageService,
    private translate: TranslateService,
    private facebookService: FacebookService,
    private store: Store<AppState>
  ) {}
}

@Injectable()
export class AuthEffects {
  private jwtHelperService: JwtHelperService;

  tokenRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RootActions.getNewAccessTokenSuccess,
        RootActions.loginSuccess,
        RootActions.sessionEnded,
        RootActions.logoutSuccess
      ),
      switchMap((a) => {
        if (
          a.type == RootActions.AuthActions.SessionEnded ||
          a.type == RootActions.AuthActions.LogoutSuccess
        ) {
          return NEVER;
        } else {
          let expireDate = this.jwtHelperService.getTokenExpirationDate(
            (<ActionPayload<AuthResult>>(<any>a)).payload.accessToken
          );

          let renewDate = dayjs(expireDate).subtract(5, 'm').toDate();

          return timer(renewDate).pipe(
            map((data) => RootActions.getNewAccessToken())
          );
        }
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.login),
      mergeMap((a) =>
        this.authService
          .login(a.payload.userName, a.payload.password, a.payload.staySignedIn)
          .pipe(
            tap((c) => {
              this.authService.storeAccessToken(c.data.accessToken);
            }),
            map((c) =>
              RootActions.loginSuccess({
                payload: { ...c.data, redirectUrl: a.payload.returnUrl },
              })
            ),
            catchError((response) =>
              of(RootActions.loginFailure({ payload: response.error }))
            )
          )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loginSuccess),
      tap((c) => {
        if (c.payload.userData.isPasswordChangeRequired) {
          this.router.navigate(['jobseeker/settings']);
        } else {
          this.router.navigate([c.payload.redirectUrl]);
        }
      }),
      map((c) => RootActions.loginRedirectSuccess())
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.logout),
      mergeMap((a) =>
        this.authService.logout().pipe(
          tap((c) => this.authService.destroyAccessToken()),
          map((c) => RootActions.logoutSuccess()),
          catchError((err) => of(RootActions.logoutFailure({ payload: err })))
        )
      )
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.logoutSuccess),
      tap((c) => this.router.navigate(['/'])),
      map((c) => RootActions.logoutRedirectSuccess())
    )
  );

  getGuid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.getGuid),
        tap(() => {
          if (!this.uniqueGuidService.getUniqueGuid()) {
            this.uniqueGuidService.generateUniqueGuid();
          }
        })
      ),
    { dispatch: false }
  );

  sendBannerViewToAnalytic = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.sendBannerViewToAnalytic),
      map((x) => {
        let bannerAnalytic = {
          bannerIdList: (x.payload.bannerIdList.bannerIdList).join('-'),
          providerWebsiteId: x.payload.providerWebsiteId,
          userId: this.authService.getUserId(),
          isAnonymous: !this.authService.isSignedIn(),
          pageId: x.payload.pageId,
          targetId: x.payload.bannerIdList.targetId
        };
        return this.bannerService.sendBannerView(bannerAnalytic)
      }),
      tap((success) => {
        if(!success){
          throw Error('error sending beacon')
        }
      })
    ),
    { dispatch: false });

  getNewToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.getNewAccessToken),
      mergeMap((a) =>
        this.authService.getNewToken().pipe(
          tap((c) => this.authService.storeAccessToken(c.data.accessToken)),
          map((c) => RootActions.getNewAccessTokenSuccess({ payload: c.data })),
          catchError((err) =>
            of(RootActions.getNewAccessTokenFailure({ payload: err }))
          )
        )
      )
    )
  );

  getNewTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.getNewAccessTokenFailure),
        tap((c) => {
          if (!c.payload.isConnectionFailure) {
            this.authService.destroyAccessToken();
          }
        }),
        catchError((err) =>
          of(RootActions.getNewAccessTokenFailure({ payload: err }))
        )
      ),
    { dispatch: false }
  );

  getCurrentUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.getCurrentUserInfo),
      mergeMap((a) =>
        this.authService.getCurrentUserInfo().pipe(
          map((c) => RootActions.getCurrenUserInfoSuccess({ payload: c.data })),
          catchError((err) =>
            of(RootActions.getCurrenUserInfoFailure({ payload: err }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private uniqueGuidService: UniqueGuidService,
    private bannerService:BannerService
  ) {
    this.jwtHelperService = new JwtHelperService();
  }
}

@Injectable()
export class SnackBarEffects {
  display$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.displaySnackBarMessage),
        tap((action) => {
          this.customSnackBarService.openSnackbar(action.payload);
        }) //TODO implement custom template for better  visual
        //delayWhen((action) => timer(action.payload.displayTime || 10000)),//TODO move default display time to config
        //map(() => RootActions.closeSnackBarMessage())
      ),
    { dispatch: false }
  );

  close$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.closeSnackBarMessage),
        tap((action) => this.snackBarService.dismiss())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBarService: MatSnackBar,
    private customSnackBarService: CustomSnackbarService
  ) {
    //console.log('running snackbar effects');
  }
}

@Injectable()
export class FavoritesEffects {
  loadFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loadFavorites),
      mergeMap(() =>
        this.announcementService.getRefreshedFavoriteIds().pipe(
          map((response) =>
            RootActions.loadFavortiesSuccess({
              payload: { announcementIds: response.data },
            })
          ),
          catchError((response) =>
            of(RootActions.loadFavoritesFail({ payload: response.error }))
          )
        )
      )
    )
  );

  loadFavoritesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.loadFavortiesSuccess),
        tap((action) =>
          this.favorites.refreshFavorites(action.payload.announcementIds)
        )
      ),
    { dispatch: false }
  );

  addToFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.addToFavorites),
      tap((action) =>
        this.favorites.addToFavorites(action.payload.announcementId)
      ),
      map(() => this.favorites.getFavorites()),
      map((data) =>
        RootActions.addToFavoritesSuccess({
          payload: { updatedFavoriteAnnouncementIds: data },
        })
      )
    )
  );

  removeFromFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.removeFromFavorites),
      tap((action) =>
        this.favorites.removeFromFavorites(action.payload.announcementId)
      ),
      map(() => this.favorites.getFavorites()),
      map((data) =>
        RootActions.removeFromFavoritesSuccess({
          payload: { updatedFavoriteAnnouncementIds: data },
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private favorites: FavoritesService,
    private announcementService: AnnouncementService
  ) {
    // console.log('running favorites effects');
  }
}
